import { ONE_ANSWER, TEXT_ANSWER, DELIVERY_ANSWER } from "./questionTypes.js";

export const requiredQuestions = {
  id: "requiredQuestions",
  name: {
    ua: "Обов'язкові питання",
    ru: "Обязательные вопросы",
    en: "Required questions",
  },
  hideTitle: true,
  questions: [
    {
      type: TEXT_ANSWER,
      text: {
        ua: "Ім'я одержувача",
        ru: "Имя получателя",
        en: "Recipient's name",
      },
    },
    {
      type: TEXT_ANSWER,
      text: {
        ua: "Прізвище одержувача",
        ru: "Фамилия получателя",
        en: "Recipient's surname",
      },
    },
    {
      type: TEXT_ANSWER,
      text: {
        ua: "Вік (можна вказати приблизний)",
        ru: "Возраст (можно указать приблизительный)",
        en: "Age (approximate age)",
      },
    },
    {
      type: DELIVERY_ANSWER,
      delivery: 'deliverySelf',
      'deliverySelf': null,
      'deliveryDenmark': {
        type: TEXT_ANSWER,
        text: {
          ua: "Адреса одержувача",
          ru: "Адрес получателя",
          en: "Address of recipient",
        },
        placeholder: {
          ua: "Країна, обл., місто / село, вулиця, будинок / кв., індекс",
          ru: "Страна, обл., город / село, улица, дом / кв., индекс",
          en: "Country, region, city / village, street, house / flat, index",
        },
      },
      'deliveryUkraine': {
        type: TEXT_ANSWER,
        text: {
          ua: "Номер найближчого відділення Нової Пошти",
          ru: "Номер ближайшего отделения Новой Почты",
          en: "Number of the closest Nova Poshta office"
        },
      },
    },
    {
      type: TEXT_ANSWER,
      text: {
        ua: "Номер телефону одержувача",
        ru: "Номер телефона получателя",
        en: "Phone number of recipient",
      },
    },
    // При відповіді «Так» потрібно вказати алергени
    {
      type: ONE_ANSWER,
      text: {
        ua: "Чи є в одержувача алергія?",
        ru: "Есть ли получателя аллергия?",
        en: "Does the recipient have allergies?",
      },
      answers: {
        ua: ["ні", "не знаю", { custom: "так" }],
        ru: ["нет", "не знаю", { custom: "да" }],
        en: ["no", "I don't know", { custom: "yes" }],
      },
    },
    {
      type: TEXT_ANSWER,
      text: {
        ua: "Особливі побажання до подарунку",
        ru: "Особенные пожелания к подарку",
        en: "Special wishes to the present",
      },
    },
    // TO DO
    // додати листівку з побажаннями або привітом/не потрібно
    {
      type: TEXT_ANSWER,
      text: {
        ua: "Текст для вітальної листівки",
        ru: "Текст для поздравительной открытки",
        en: "Text for a greeting card",
      },
    },
    {
      type: TEXT_ANSWER,
      text: {
        ua: "Ім'я та прізвище замовника",
        ru: "Имя и фамилия заказчика",
        en: "Name and surname of customer",
      },
    },
    {
      type: TEXT_ANSWER,
      text: {
        ua: "Номер телефону замовника",
        ru: "Номер телефона заказчика",
        en: "Phone number of customer",
      },
    },
    {
      type: TEXT_ANSWER,
      text: {
        ua: "Електронна адреса замовника",
        ru: "Электронный адрес заказчика",
        en: "E-mail of customer",
      },
    },
  ],
};

// all questions except question
// about age for children surprise box
const questionsWithoutAge =
  requiredQuestions.questions.filter(q => q.text == null || !q.text.en.startsWith('Age'));
const questionsChildren = questionsWithoutAge;

export const requiredQuestionsChildren = {
  id: "requiredQuestionsChildren",
  name: requiredQuestions.name,
  questions: questionsChildren,
};

// for month box remove "Special wishes to the present" question
const questionsMonthBox =
  questionsWithoutAge.filter(q => q.text == null || !q.text.en.startsWith('Special wishes'));

export const requiredQuestionsMonthBox = {
  id: "requiredQuestionsMonthBox",
  name: requiredQuestions.name,
  questions: questionsMonthBox,
};
