export const sendJsonPost = (url, obj) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  });
};

const uahApiUrl = 'https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json';
export const getUahRate = (currency) =>
  fetch(uahApiUrl)
    .then(res => res.json())
    .then(res => {
      for (const key in res)
        if (res[key].cc === currency)
          return res[key].rate
    });

const dkkApiUrl = 'https://www.nationalbanken.dk/_vti_bin/DN/DataService.svc/CurrencyRatesXML?lang=en';
