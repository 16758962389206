import {
  ONE_ANSWER,
  MULTIPLE_ANSWER,
  TEXT_ANSWER,
  RANGE_ANSWER,
  CHILDREN_AGE_ANSWER,
} from "./questionTypes.js";

export const childrenQuestions = {
  id: "children",
  name: {
    ua: "Для дитини",
    ru: "Для ребёнка",
    en: "For child",
  },
  hideTitle: true,
  questions: [
    {
      type: ONE_ANSWER,
      text: {
        ua: "Для кого подарунок",
        ru: "Для кого подарок",
        en: "Who's that for",
      },
      answers: {
        ua: ["Для дівчинки", "Для хлопчика"],
        ru: ["Для девочки", "Для мальчика"],
        en: ["For girl", "For boy"],
      },
    },
    {
      type: CHILDREN_AGE_ANSWER,
      text: {
        ua: "Вік",
        ru: "Возраст",
        en: "Age",
      }
    },
    {
      type: TEXT_ANSWER,
      text: {
        ua: "Захоплення",
        ru: "Увлечения",
        en: "Hobbies",
      },
      placeholder: {
        ua: "Малювання, футбол, шахи, плавання, ігри",
        ru: "Рисование, футбол, шахматы, плаванье, игры",
        en: "Drawing, football, chess, swimming, games",
      },
    },
  ],
};
