import styles from "./Footer.module.css";
import { Link } from 'react-router-dom';
// import visaMastercardLogo from "../assets/visa-mastercard.png";
import masterCardLogo from "../assets/mastercard-white-logo2.png";
import visaLogo from "../assets/visa-white-logo2.png";
import paypalLogo from "../assets/paypal-white-logo3.png";
import mobilePayLogo from "../assets/mobilepay-logo-white2.png";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useToggle = (activeClass) => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(prev => !prev);
  const active = show ? activeClass : '';
  return [toggle, active];
}

const Footer = (props) => {
  const { t } = useTranslation();
  const [toggle1, col1Active] = useToggle(styles.active);
  const [toggle2, col2Active] = useToggle(styles.active);
  const [toggle3, col3Active] = useToggle(styles.active);
  const [toggle4, col4Active] = useToggle(styles.active);
  const getColCSS = (css) => styles.columnContent + ' ' + css;
  return (
    <div className={styles.footer}>
      <div className={styles.column + ' ' + styles.links}>
        <h1 onClick={toggle1}>{t('documents')}</h1>
        <ul className={getColCSS(col1Active)}>
          <li>
            <Link to="/terms" className={styles.link} onClick={() => window.scroll(0, 0)}>
              {t('terms')}
            </Link>
          </li>
          <li>
            <Link to="/shipping" className={styles.link} onClick={() => window.scroll(0, 0)}>
              {t('shipping')}
            </Link>
          </li>
          <li>
            <Link to="/privacy" className={styles.link} onClick={() => window.scroll(0, 0)}>
              {t('privacy-policy')}
            </Link>
          </li>
        </ul>
      </div>
      <div className={styles.column}>
        <h1 onClick={toggle2}>{t('company-info')}</h1>
        <ul className={getColCSS(col2Active)}>
          <li>Name: MaLaV I/S</li>
          <li>CVR: 42824089</li>
          <li>Address: Sønderbrogade 48, 8700 Horsens</li>
        </ul>
      </div>
      <div className={styles.column}>
        <h1 onClick={toggle3}>{t('contacts')}</h1>
        <ul className={getColCSS(col3Active)}>
          <li title="Phone">
            <a className={styles.link} href="tel:+4542609106">+45 42 60 91 06</a>
          </li>
          <li title="E-mail">
            <a className={styles.link} href="mailto:malav.business.dk@gmail.com">malav.business.dk@gmail.com</a>
          </li>
          <li title="Postbox">
            Sønderbrogade 48, 8700 Horsens
          </li>
        </ul>
      </div>
      <div className={styles.column}>
        <h1 onClick={toggle4}>{t('payment-methods')}</h1>
        <ul className={getColCSS(col4Active) + ' ' + styles.paymentRowCont}>
          <li className={styles.paymentsRow}>
            <img src={paypalLogo} alt="PayPal logo" />
            <img src={masterCardLogo} alt="Mastercard logo" />
            <img src={visaLogo} alt="Visa logo" />
            <img src={mobilePayLogo} alt="MobilePay logo" />
          </li>
          {/* <li className={styles.paymentsRow}>
            <img src={visaLogo} alt="Visa logo" />
            <img src={paypalLogo} alt="PayPal logo" />
          </li> */}
          <li className={styles.paymentsMobile}>
            <img src={paypalLogo} alt="PayPal logo" />
            <img src={masterCardLogo} alt="Mastercard logo" />
            <img src={visaLogo} alt="Visa logo" />
            <img src={mobilePayLogo} alt="MobilePay logo" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
