import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Page from "./Page";
import Title from '../components/Title';
import Button from "../components/Button";
import Quiz from '../components/Quiz';
import ChooseCurrency from '../components/ChooseCurrency';
import ChoosePrice from '../components/ChoosePrice';
import ChooseDelivery from '../components/ChooseDelivery';
import ChooseBoxType from '../components/ChooseBoxType';
import ShowPrice from '../components/ShowPrice';
import styles from './OrderSurpriseAdult.module.css';
import { chooseSurpriseChildren } from '../store/quizSlice';

const ChoosePricePage = (props) => {
  return (
    <div className={styles.selectBox}>
      <ChooseCurrency />
      <ChooseDelivery />
      <ChooseBoxType />
      <ShowPrice />
      <ChoosePrice />
    </div>
  );
}

const OrderSurpriseChildren = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(chooseSurpriseChildren());
  }, [dispatch]);

  const [index, setIndex] = useState(0);
  const pages = [
    () => <ChoosePricePage />,
    () => <Quiz goPrev={() => setIndex(0)} />
  ];
  const centerStyle = {
    width: "fit-content",
    margin: "1.2rem auto",
  };
  const onNextClick = () => {
    setIndex(index + 1);
    window.scroll(0, 0);
  };
  const btn = <Button onClick={onNextClick}
                      style={centerStyle}>{t('next-btn')}</Button>;
  const button = index !== pages.length - 1 ? btn : null;

  return (
    <Page>
      <Title text={t('surprise-order-children')} />
      {pages[index]()}
      {button}
    </Page>
  );
};

export default OrderSurpriseChildren;
