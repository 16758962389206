import Button from './Button';
import ShowAnswers from './ShowAnswers';
import styles from './ShowOrder.module.css';

const ShowOrder = (props) => {
  const { order } = props;
  const { orderStatus, isPaymentTest } = props;
  const { id, paymentId, createdAt } = order;
  const { type, currency, boxType, deliveryType } = order;
  const { giftPrice, allPrice, answers } = order;

  const onRemove = () => {
    console.log(`Try remove ${id}`);
    if (!window.confirm(`Do you want to remove ${id} ?`))
      return;
    fetch(`/api/payments/process/${id}`, {
      method: 'DELETE',
      headers: {
        'Api-Key': localStorage.getItem('token')
      }
    }).then(res => {
      if (res.ok)
        props.onRemove(id);
      else
        alert(`Error removing order ${id}`);
    });
  };

  const onProcess = () => {
    fetch(`/api/payments/process/${id}`, {
      method: 'POST',
      headers: {
        'Api-Key': localStorage.getItem('token')
      }
    }).then(res => {
      if (res.ok)
        props.onProcess();
      else
        alert(`Error processing order ${id}`);
    });
  };
  const date = createdAt ? new Date(createdAt).toLocaleString() : null;
  // <h2>Payment: {isPaymentTest ? "test" : "real (not test!)"}</h2>
  return (
    <>
      <h2>Order Id: {id}</h2>
      <h2>Payment id: {paymentId}</h2>
      <h2>Status: {orderStatus}</h2>
      <h2>Created at: {date}</h2>
      <h2>{type}</h2>
      <h2>{boxType}</h2>
      <h2>{deliveryType}</h2>
      <h2>
        Ціна подарунка: <span className={styles.price}>{giftPrice} {currency}</span>
      </h2>
      <h2>Вся ціна: {allPrice} {currency}</h2>
      <div className={styles.buttons}>
        <Button onClick={onRemove}>Remove</Button>
        <Button onClick={onProcess}>Process</Button>
      </div>
      <h2>Анкета</h2>
      <ShowAnswers answers={answers} />
    </>
  );
};

export default ShowOrder;
