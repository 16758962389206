import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

// Info pages
import Main from './pages/Main';
import About from './pages/About';
import Terms from './pages/Terms';
import Details from './pages/Detailed';
import Privacy from './pages/Privacy';
import Shipping from './pages/Shipping';
import Guarantees from './pages/Guarantees';
import Contacts from './pages/Contacts';
//Admin Pages
import AdminPage from './pages/AdminPage';
import AdminResourcesPage from './pages/AdminResourcesPage';
// Order pages
import OrderFor from './pages/OrderFor';
import OrderSurprise from './pages/OrderSurprise';
import OrderSurpriseAdult from './pages/OrderSurpriseAdult';
import OrderSurpriseAdultFor from './pages/OrderSurpriseAdultFor';
import OrderSurpriseChildren from './pages/OrderSurpriseChildren';
import OrderTestPage from './pages/OrderTestPage';
import OrderMonthBoxFor from './pages/OrderMonthBoxFor';
import OrderMonthBoxPage from './pages/OrderMonthBoxPage';
import ThankYouPage from './pages/ThankYouPage';

import './style.css';
import { setResources } from './store/resourcesSlice';
import { setPrices } from './store/quizSlice';

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetch('/api/resources/public')
      .then(res => res.json())
      .then(resources => {
        dispatch(setResources({ resources }));
        dispatch(setPrices({ resources }));
      });
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin/resources" element={<AdminResourcesPage />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/about" element={<About />} />
        <Route path="/guarantees" element={<Guarantees />} />
        <Route path="/detailed" element={<Details />} />
        <Route path="/order/success/:id" element={<ThankYouPage />} />
        <Route path="/order" element={<OrderFor />} />
        <Route path="/order/test" element={<OrderTestPage />} />
        <Route path="/order/surprise" element={<OrderSurprise />} />
        <Route path="/order/surprise-adult" element={ <OrderSurpriseAdultFor /> } />
        <Route path="/order/surprise-adult/men" element={ <OrderSurpriseAdult for="men" /> } />
        <Route path="/order/surprise-adult/women" element={ <OrderSurpriseAdult for="women" /> } />
        <Route path="/order/surprise-adult/couple" element={ <OrderSurpriseAdult for="couple" /> } />
        <Route path="/order/surprise-children" element={<OrderSurpriseChildren />} />
        <Route path="/order/month" element={<OrderMonthBoxFor />} />
        <Route path="/order/month/men" element={<OrderMonthBoxPage surpriseFor="men" />} />
        <Route path="/order/month/women" element={<OrderMonthBoxPage surpriseFor="women" />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
