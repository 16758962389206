import './RadioButton.css';

const RadioButton = (props) => {
  const { text, inputName, checked, onChange } = props;

  return (
    <p>
      <label>
        <input type="radio"
          name={inputName}
          checked={checked}
          onChange={() => onChange(undefined)} />
        <span>{text}</span>
      </label>
    </p>
  );
};

export default RadioButton;
