import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import styles from './Quiz.module.css';
import Button from './Button';
import OneAnswerQuestion from './OneAnswerQuestion';
import MultipleAnswerQuestion from './MultipleAnswerQuestion';
import TextAnswerQuestion from './TextAnswerQuestion';
import RangeAnswerQuestion from './RangeAnswerQuestion';
import ChildrenAgeQuestion from './ChildrenAgeQuestion';
import OrderPreview from "./OrderPreview";
import { ONE_ANSWER, MULTIPLE_ANSWER,
         TEXT_ANSWER, RANGE_ANSWER, DELIVERY_ANSWER } from "../store/questionTypes.js";
import { changeAnswer as changeAnswerAction } from '../store/quizSlice';
import { selectAnswers, selectQuestionnaire, selectValidCategories } from '../store/quizSlice';
import { sendJsonPost } from '../api';
import Checkbox from "./Checkbox";

const centerStyle = {
  width: "fit-content",
  margin: "1.2rem auto",
};

const Question = (props) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const { categoryIndex, questionIndex } = props;
  const { question, answer } = props.questionAnswer;

  const { onlyOne } = question;
  const text = question.text ? question.text[language] : null;
  const dispatch = useDispatch();
  const changeAnswer = (obj) =>
    dispatch(changeAnswerAction({
      categoryIndex, questionIndex, ...obj
    }));
  const onChange = (value) => changeAnswer({ answer: value });

  if (question.text != null && question.text.en === 'Age') {
    const questionProps = {
      text,
      value: answer,
      onChange,
    };
    return (
      <ChildrenAgeQuestion {...questionProps} />
    );
  }
  if (question.type === DELIVERY_ANSWER) {
    console.log('question');
    console.log(question);
    const deliveryType = question.delivery;
    console.log('deliveryType is: ' + deliveryType);
    console.log('question[deliveryType] is: ' + question[deliveryType]);
    if (deliveryType === 'deliverySelf')
      return null;
    const questionProps = {
      text: question[deliveryType].text[language],
      onlyOne: false,
      placeholder: deliveryType === 'deliveryDenmark' ? question[deliveryType].placeholder[language] : undefined,
      answerText: answer,
      onChange
    };
    return (
      <TextAnswerQuestion {...questionProps} />
    );
  }
  if (question.type === RANGE_ANSWER) {
    const { min, max, step } = question;
    const questionProps = {
      min, max, step,
      text, onlyOne,
      value: answer,
      onChange,
    };
    return (
      <RangeAnswerQuestion {...questionProps} />
    );
  }
  if (question.type === TEXT_ANSWER) {
    const questionProps = {
      text, onlyOne,
      placeholder: question.placeholder ? question.placeholder[language] : undefined,
      answerText: answer,
      type: question.text.en.startsWith('Phone') ? 'phone' : null,
      onChange
    };
    return (
      <TextAnswerQuestion {...questionProps} />
    );
  }

  if (question.type === ONE_ANSWER) {
    const questionProps = {
      text, onlyOne,
      options: question.answers[language],
      selected: answer,
      onChange: (answer) => changeAnswer({ answer }),
      inputName: `radio${categoryIndex}-${questionIndex}`,
    };
    return (
      <OneAnswerQuestion {...questionProps} />
    );
  }
  if (question.type === MULTIPLE_ANSWER) {
    const questionProps = {
      text, onlyOne,
      options: question.answers[language],
      selected: answer,
      onChange: (newSelected) => changeAnswer({ selected: newSelected })
    };
    return (
      <MultipleAnswerQuestion {...questionProps} />
    );
  }
}

const QuestionCategory = (props) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  // category: { name,
  //             questions: [
  //             { type, ua: {text, answer} } ]
  const { categoryIndex, category } = props;
  const showTitle = !category.hideTitle && category.questionsAnswers.length > 1;
  const smth = category.questionsAnswers.map(
    (qa, i) => {
      return (
        <>
          <Question key={i}
                    categoryIndex={categoryIndex}
                    questionIndex={i}
                    questionAnswer={qa} />
          <p className={styles.validationMessage}>
            {qa.validationError !== null && t(qa.validationError)}
          </p>
        </>
      );
    }
  );
  return (
    <>
      {showTitle && <h1>{category.name[language]}</h1>}
      {smth}
    </>
  );
};

const SendData = (props) => {
  const { t } = useTranslation();
  const [failed, setFailed] = useState(false);
  const data = useSelector(selectQuestionnaire);
  console.log('Data to send');
  console.log(data);
  useEffect(() => {
    const send = async () => {
      try {
        const res = await sendJsonPost('/api/payments/submit', data);
        if (!res.ok)
          throw new Error("Invalid HTTP Request");
        const resp = await res.json();
        const { url } = resp;
        window.location.replace(url);
      }
      catch (error) {
        setFailed(true);
      }
    };
    send();
  }, [data]);

  if (failed)
    return (
      <div className={styles.message}>
        <h1>{t('order-failed-1')}</h1>
        <p>{t('order-failed-2')}</p>
      </div>
    );
  return (
    <div className={styles.message}>
      <h1>Waiting...</h1>
    </div>
  );
};

const Quiz = (props) => {
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const onAgreeWithTermsChange = (event) => setAgreeWithTerms(event.target.checked);
  const { t } = useTranslation();
  const answers = useSelector(selectAnswers).map((category, index) => {
    return category.questionsAnswers.length ? { category, index } : null;
  }).filter(obj => obj);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const categoriesCount = answers.length;
  const isAnswersPreview = categoryIndex === categoriesCount;
  // category index is index in selected categories
  // but real index is used, because there are
  // categories without questions, that are hidden
  const valid = useSelector(selectValidCategories);
  const validAnswers = categoryIndex >= categoriesCount ||
                       valid[answers[categoryIndex].index];
  // LINE BELOW IS ONLY FOR TESTING
  // const validAnswers = true;
  console.log('validAnswers');
  console.log(validAnswers);

  const leftClick = () => {
    if (categoryIndex !== 0)
      setCategoryIndex(categoryIndex - 1);
    else
      props.goPrev();
    window.scroll(0, 0);
  };
  const rightClick = () => {
    if (!validAnswers)
      return;
    if (categoryIndex === categoriesCount && !agreeWithTerms)
      return;
    setCategoryIndex(categoryIndex + 1);
    window.scroll(0, 0);
  };
  if (categoryIndex === categoriesCount + 1)
    return (
      <SendData />
    );
  const buttonsStyle = { display: "flex", ...centerStyle };
  const invalidStyle = { color: "#ff0000", ...centerStyle };
  const allowNextButton = (categoryIndex < categoriesCount && validAnswers) || agreeWithTerms;
  const iconRight = allowNextButton ? "fa-sharp fa-solid fa-long-arrow-right" : "fa-sharp fa-solid fa-ban";
  let buttonRightContent = (
    <i className={iconRight}></i>
  );
  if (isAnswersPreview && agreeWithTerms)
    buttonRightContent = t('pay-btn');

  return (
    <>
      {
        isAnswersPreview ?
        <>
          <OrderPreview />
          <div style={centerStyle}>
            <Checkbox checked={agreeWithTerms} onChange={onAgreeWithTermsChange}>
              {t('agree-terms-1')}
              <NavLink className={styles.agree} to="/terms" target="_blank">{t('agree-terms-2')}</NavLink>
            </Checkbox>
          </div>
        </>
        :
          <>
            <div className={styles.questions}>
              <QuestionCategory category={answers[categoryIndex].category}
                                categoryIndex={answers[categoryIndex].index}
                                key={categoryIndex} />
            </div>
          </>
      }
      {
        !validAnswers && <p style={invalidStyle}>{t('fill-all-forms')}</p>
      }
      <div style={buttonsStyle}>
        <Button onClick={leftClick}>
          <i className="fa-sharp fa-solid fa-long-arrow-left"></i>
        </Button>
        <Button onClick={rightClick} style={{marginLeft: "0.7rem"}}>
          {buttonRightContent}
        </Button>
      </div>
    </>
  );
};

export default Quiz;
