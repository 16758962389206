export const isEmptyStr = (x) => typeof x === 'string' && x.trim() === '';

const getAgeInYearsText = (years, yearsTexts) => {
  if (years === 1)
    return `${years} ${yearsTexts[0]}`;
  if (years < 5)
    return `${years} ${yearsTexts[1]}`;
  return `${years} ${yearsTexts[2]}`;
};

const getAgeInMonthsText = (months, monthsTexts) => {
  if (months === 1)
    return `${months} ${monthsTexts[0]}`;
  if (months < 5)
    return `${months} ${monthsTexts[1]}`;
  return `${months} ${monthsTexts[2]}`;
};

export const numToTextAge = (num, yearsTexts, monthsTexts) => {
  if (num <= 11)
    return getAgeInMonthsText(num, monthsTexts);
  return getAgeInYearsText(num - 11, yearsTexts);
};

export const onPhoneKeyDown = (event) => {
  const left = 37;
  const right = 39;
  const space = 8;
  const backspace = 32;
  const zeroCode = '0'.charCodeAt(0);
  const digits = Array.from({ length: 10 }, (_, i) => zeroCode + i);
  const numpadZeroCode = 96;
  const numpadDigits = Array.from({ length: 10 }, (_, i) => numpadZeroCode + i);
  const phoneKeys = [space, backspace, left, right, ...digits, ...numpadDigits];
  if (!phoneKeys.includes(event.keyCode))
    event.preventDefault();
};

export const isEmailValid = (text) => {
  const mailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return text.match(mailPattern);
};

export const isValidNumber = (num) => {
  if (typeof num === 'number')
    return true;
  if (typeof num != 'string')
    return false;
  return !isNaN(num) && !isNaN(parseFloat(num));
}

export const capitalize = (word) => word[0].toUpperCase() + word.slice(1);
