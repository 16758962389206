import Checkbox from './Checkbox';
import styles from './MultipleAnswerQuestion.module.css';
import { useTranslation } from 'react-i18next';

const MultipleAnswerQuestion = (props) => {
  const { text, onlyOne, options, selected, onChange } = props;
  const { t } = useTranslation();
  const last = options.length - 1;
  // console.log('selected =', selected);
  const checkBoxes = options.map((option, index) => {
    const customSelected = index === last && typeof selected[last] === 'string';
    const onComponentChange = (checked) => {
      const newSelected = selected.map((s, i) => {
        if (i === index && i === last &&
            typeof checked === 'string')
          return checked;
        return i === index ? checked : s;
      });
      // console.log('newSelected =', newSelected);
      onChange(newSelected, selected);
    };
    const onCheckboxChange = (event) => onComponentChange(event.target.checked);
    const onTextChange = (event) => onComponentChange(event.target.value);
    const checked = customSelected || selected[index];
    const text = typeof option === 'string' ? option : option.custom;
    return (
      <div key={index.toString()}>
        <Checkbox text={text}
                  checked={checked}
                  onChange={onCheckboxChange} />
        {
          customSelected &&
            <input type="text" value={selected[index]}
                   onChange={onTextChange} />
        }
      </div>
    );
  });
  const container = text === t('choose-categories') ? styles.checkBoxesContainer : '';
  return (
    <>
      {
        onlyOne ?
          <h1>{text}</h1> :
          <p className={styles.questionText}>{text}</p>
      }
      <div className={container}>{checkBoxes}</div>
    </>
  );
};

export default MultipleAnswerQuestion;
