import { useTranslation } from 'react-i18next';

import Page from "./Page";
import Title from '../components/Title';
import Link from '../components/Link';
import styles from './OrderSurprise.module.css';

const OrderSurpriseAdultFor = (props) => {
  const { t } = useTranslation();
  return (
    <Page>
      <div className={styles.container}>
        <Title text={t('order-surprise-box')} />
        <div className={styles.buttons}>
          <Link to="/order/surprise-adult/men" className={styles.btn}>
            {t('surprise-adult-men')}
          </Link>
          <Link to="/order/surprise-adult/women" className={styles.btn}>
            {t('surprise-adult-women')}
          </Link>
          <Link to="/order/surprise-adult/couple" className={styles.btn}>
            {t('surprise-adult-couple')}
          </Link>
        </div>
      </div>
    </Page>
  );
};

export default OrderSurpriseAdultFor;
