import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Page from "./Page";
import Title from "../components/Title";
import Button from "../components/Button";
import Quiz from "../components/Quiz";
import ChooseCurrency from '../components/ChooseCurrency';
import ChoosePrice from '../components/ChoosePrice';
import ChooseDelivery from '../components/ChooseDelivery';
import ChooseBoxType from '../components/ChooseBoxType';
import ShowPrice from '../components/ShowPrice';
import MultipleAnswerQuestion from "../components/MultipleAnswerQuestion";

import { chooseCategories, chooseSurpriseAdult,
         selectAllCategories, selectSelectedCategories,
         setNoCategoriesError, selectPriceInfo } from "../store/quizSlice";
import styles from "./OrderSurpriseAdult.module.css";

const centerStyle = {
  width: "fit-content",
  margin: "1.2rem auto",
};

const ChooseCategories = (props) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const dispatch = useDispatch();

  const priceInfo = useSelector(selectPriceInfo);

  const categories =
    useSelector(selectAllCategories).map(c => c[language]);
  const selectedCategories =
    useSelector(selectSelectedCategories);
  const onCategoriesChange = (sel, old) => {
    dispatch(chooseCategories({
      selected: sel
    }));
  };

  return (
    <>
      <div className={styles.selectBox}>
        <ChooseCurrency />
        <ChooseDelivery />
        <ChooseBoxType />
        <ShowPrice />
        <ChoosePrice />
        <p>{t('you-can-choose-up') + priceInfo.categoriesAllowed + t('categories')}</p>
        {
          priceInfo.error &&
          <p className={styles.extraError}>
            {t(priceInfo.error)}
          </p>
        }
        <MultipleAnswerQuestion text={t('choose-categories')}
                                options={categories}
                                selected={selectedCategories}
                                onChange={onCategoriesChange} />
      </div>
    </>
  );
};

const OrderSurpriseAdult = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(chooseSurpriseAdult({
      for: props.for
    }));
  }, [dispatch]);

  const selectedCategories = useSelector(selectSelectedCategories);
  const noCategories = selectedCategories.filter(c => c).length === 0;

  const [index, setIndex] = useState(0);
  const pages = [
    () => <ChooseCategories />,
    () => <Quiz goPrev={() => setIndex(0)} />
  ];
  const onNextClick = () => {
    if (noCategories)
      dispatch(setNoCategoriesError());
    else {
      setIndex(index + 1);
      window.scroll(0, 0);
    }
  };
  const btn = <Button onClick={onNextClick}
                      style={centerStyle}>{t('next-btn')}</Button>;
  const button = index !== pages.length - 1 ? btn : null;
  const title = `order-surprise-${props.for}`;
  return (
    <Page>
      <Title text={t(title)} />
      {pages[index]()}
      {button}
    </Page>
  );
};

export default OrderSurpriseAdult;
