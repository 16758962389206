import Range from './Range';

const RangeAnswerQuestion = (props) => {
  const { text, onlyOne,
          min, max, step, value,
          onChange, onShowCaption } = props;
  const left = onShowCaption ? onShowCaption(min) : min;
  const right = onShowCaption ? onShowCaption(max) : max;
  return (
    <>
      {
        onlyOne ?
          <h1>{text}</h1> :
          <p className={'question-text'}>{text}</p>
      }
      <Range min={min} max={max} step={step}
             leftLabel={left} rightLabel={right} value={value}
             onChange={onChange} onShowCaption={onShowCaption} />
    </>
  );
};

export default RangeAnswerQuestion;
