import styles from './UpTitleInput.module.css';

const UpTitleInput = (props) => {
  const type = props.type || 'text';
  const id = 'up-input-' + props.placeholder;
  return (
    <div className={styles['input-field']}>
      <input id={id}
             type={type}
             className={styles.validate}
             onChange={props.onChange}
             onKeyDown={props.onKeyDown}
             value={props.value} />
      <label className={styles.active} htmlFor={id}>{props.placeholder}</label>
    </div>
  );
};

export default UpTitleInput;
