import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectPriceInfo, selectDeliveryInfo, selectBoxInfo } from '../store/quizSlice';
import styles from './ShowPrice.module.css';

const ShowPrice = () => {
  const { t } = useTranslation();

  const priceInfo = useSelector(selectPriceInfo);
  let currency = priceInfo.currency.toUpperCase();
  const { deliveryPrice } = useSelector(selectDeliveryInfo);
  const { boxPrice } = useSelector(selectBoxInfo);

  const { giftPrice } = priceInfo;
  const allPrice = giftPrice + deliveryPrice + boxPrice;

  return (
    <>
      <p className={styles.price}>{t('price-all')}: {allPrice} {currency}</p>
      <p className={styles.price}>{t('price-is')}: {giftPrice} {currency}</p>
    </>
  );
};

export default ShowPrice;
