import InfoPage from "./InfoPage";
import { useTranslation } from 'react-i18next';
import styles from "./Shipping.module.css";

// const text =
// `Shipping and postage
// We offer delivery in Denmark and Ukraine, however delivery options can be extended to worldwide.
// All orders in Denmark are shipped with PostNord, orders in Ukraine are shipped with NovaPoshta.
// Shipping costs depend on the selected country and method.
// If you have any questions about the status of your order, you are of course welcome to contact us.

// Delivery time
// The exact delivery time of an order depends on destination. In general, your order will be delivered at the date you have specified in the questionary. However, you can expect that delivery within Denmark takes up to 7 days and delivery within Ukraine - 14 days.
// For worldwide deliveries, please, contact us separately.

// Right of cancellation
// According to the Danish Sale of Goods Act, you always have 14 days right of cancellation, when you shop online. The right of cancellation runs from the day you receive your goods. If an item is taken into use before the expiration of the right of cancellation, and if you should hereafter regret your buy, you yourself are liable for any depreciation in the value of the item which is due to any other handling than the one necessary to test the item in a regular manner in a physical store. Therefore you should make sure to always return all items in the same condition as at receipt. It is a good idea to always keep the original packaging until you have decided to keep the item.
// If you wish to utilize your right of cancellation you need to contact us via e-mail: malav.business.dk@gmail.com
// You yourself are responsible for the payment of the return, and it is your liability that all items are returned safely to us.
// When we receive your returned items, we pay you back the full price of your original order.
// If it is estimated that any return item has been used as described above, you will only receive some or nothing of the price back depending on the commercial value of the item. Please note that it can take up to 2 weeks from the receipt of the return item until your money is returned.
// Return items to be sent to:
// MaLaV I/S
// Sønderbrogade 48, 8700 Horsens, Denmark
// If you have any questions, you are most welcome to contact us:
// Tel.: +4542609106
// E-mail: malav.business.dk@gmail.com`;

const Delivery = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.pageInfo}>
      <h2>{t('delivery-title')}</h2>
      <p>{t('delivery-info')}</p>
    </div>
  );
};

const DeliveryTerms = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.pageInfo}>
      <h2>{t('delivery-terms-title')}</h2>
      <p>{t('delivery-terms-info')}</p>
    </div>
  );
};

const RightOfCancellation = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.pageInfo}>
      <h2>{t('delivery-cancellation-title')}</h2>
      <p>{t('delivery-cancellation-info')}</p>
    </div>
  );
};

const Shipping = (props) => {
  const { t } = useTranslation();
  return (
    <InfoPage title={t('shipping')}>
      <div className={styles.content}>
        <Delivery />
        <DeliveryTerms />
        <RightOfCancellation />
        <p>{t('delivery-contact-info')}</p>
      </div>
    </InfoPage>
  );
};

export default Shipping;
