import styles from './Range.module.css';

const Range = (props) => {
  const { min, max, step, leftLabel, rightLabel } = props;
  const onShowCaption = props.onShowCaption;
  const { onChange, value } = props;
  const change = (e) => {
    const rangeValue = parseInt(e.target.value);
    onChange(rangeValue);
  };
  return (
    <>
      <div className={styles.container}>

        <p className={styles.label}>
          {leftLabel}
        </p>
        <div>
          {
            onShowCaption &&
            <div className={styles.centerLabel}>
              {onShowCaption(value)}
            </div>
          }
          <input className={styles.range} type="range"
                 step={step} min={min} max={max} value={value}
                 onChange={change} />
        </div>
        <p className={styles.label}>
          {rightLabel}
        </p>
      </div>
    </>
  );
};

export default Range;
