import { createSlice } from "@reduxjs/toolkit";

const textObject = () => ({
  ua: '', ru: '', en: ''
});

const descriptionObject =
  () => ({
    text: textObject(),
    title: textObject()
  });

const initialState = {
  monthBoxMenDescription: descriptionObject(),
  monthBoxWomenDescription: descriptionObject()
};

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setResources: (state, action) => {
      const { resources } = action.payload;
      for (const resource of resources) {
        // example id = 'monthBoxMenDescriptionUa'
        const { id, value, title } = resource;
        // example field = 'monthBoxMenDescription'
        for (const field in state) {
          if (!id.startsWith(field))
            continue;
          const len = id.length;
          // example lang = 'ua'
          const lang = id.substring(len - 2, len).toLowerCase();
          state[field].text[lang] = value;
          state[field].title[lang] = title;
        }
      }
    }
  }
});

export const selectMonthBoxMenDescription = (state) => state.resources.monthBoxMenDescription;
export const selectMonthBoxWomenDescription = (state) => state.resources.monthBoxWomenDescription;
export const { setResources } = resourcesSlice.actions;

export default resourcesSlice.reducer;
