import Page from "./Page";
import Title from '../components/Title';
import styles from "./Detailed.module.css";
import { useTranslation } from 'react-i18next';

const Detailed = (props) => {
  const { t } = useTranslation();

  return (
    <Page>
      <div className={styles.container}>
        <Title text={t('detailed')} />
        <div className={styles.content}>
          <h2>{t('detailed-question')}</h2>

          <h3>{t('detailed-title-1')}</h3>
          <p>{t('detailed-1')}</p>

          <h3>{t('detailed-title-2')}</h3>
          <p>{t('detailed-2')}</p>

          <h3>{t('detailed-title-3')}</h3>
          <p>{t('detailed-3')}</p>

          <h3>{t('detailed-title-4')}</h3>
          <p>{t('detailed-4')}</p>

          <h3>{t('detailed-title-5')}</h3>
          <p>{t('detailed-5')}</p>

          <h3>{t('detailed-title-6')}</h3>
          <p>{t('detailed-6')}</p>

          <h3>{t('detailed-title-7')}</h3>
          <p>{t('detailed-7')}</p>
          
          <h3>{t('detailed-resume')}</h3>
        </div>
      </div>
    </Page>
  );
};

export default Detailed;
