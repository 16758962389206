import { ONE_ANSWER, MULTIPLE_ANSWER, TEXT_ANSWER } from "./questionTypes.js";

const malavChoice = {
  id: "malavChoice",
  name: {
    ua: 'Підбір наповнення боксу на розсуд команди MaLaV',
    ru: 'Подбор наполнения бокса по усмотрению команды MaLaV',
    en: 'Selection of the box content by MaLaV team',
  },
};

const cosmetics = {
  id: "cosmetics",
  name: {
    ua: 'Косметика',
    ru: 'Косметика',
    en: 'Cosmetics',
  },
  questions: [
    {
      type: ONE_ANSWER,
      text: {
        ua: 'Колір волосся',
        ru: 'Цвет волос',
        en: 'Hair color',
      },
      answers: {
        ua: ['блонд', 'брюнет', 'шатен', { custom: 'свій варіант відповіді' }],
        ru: ['блонд', 'брюнет', 'шатен', { custom: 'свой вариант ответа' }],
        en: ['blond', 'brunette', 'brown', { custom: 'your answer' }],
      }
    },

    {
      type: ONE_ANSWER,
      text: {
        ua: 'Колір шкіри',
        ru: 'Цвет кожи',
        en: 'Skin color',
      },
      answers: {
        ua: ['світла', 'засмагла', 'темна'],
        ru: ['светлая', 'загорелая', 'темная'],
        en: ['light', 'tanned', 'dark'],
      }
    },

    {
      type: ONE_ANSWER,
      text: {
        ua: 'Тип шкіри обличчя',
        ru: 'Тип кожи лица',
        en: 'Skin type',
      },
      answers: {
        ua: ['нормальна', 'суха', 'жирна', 'комбінована', 'зріла', 'чутлива', 'не знаю'],
        ru: ['нормальная', 'сухая', 'жирная', 'комбинированная', 'зрелая', 'чувствительная', 'не знаю'],
        en: ['normal', 'dry', 'oily', 'combined', 'mature', 'sensitive', "I don't know"],
      }
    },

    {
      type: MULTIPLE_ANSWER,
      text: {
        ua: 'Яким ароматам одержувач надає перевагу?',
        ru: 'Какие ароматы получатель предпочитает?',
        en: 'Which fragrances does the recipient prefer?',
      },
      answers: {
        ua: ['унісекс', 'свіжий', 'квітковий', 'солодкий', 'не знаю', { custom: 'свій варіант відповіді' }],
        ru: ['унисекс', 'свежий', 'цветочный', 'сладкий', 'не знаю', { custom: 'cвой вариант ответа' }],
        en: ['unisex', 'fresh', 'floral', 'sweet', 'I do not know', { custom: 'your answer' }],
      }
    },

    {
      type: ONE_ANSWER,
      text: {
        ua: '(Лише для чоловіків) Чи носить бороду?',
        ru: '(Только для мужчин) Носит ли бороду?',
        en: '(Only for men) Does he wear a beard?',
      },
      answers: {
        ua: ['так, потребує особливого догляду', 'так, не потребує особливого догляду', 'ні'],
        ru: ['да, требует особого ухода', 'да, не требует особого ухода', 'нет'],
        en: ['yes, it requires special care', "yes, it doesn't require special care", 'no'],
      }
    },
  ]
};

const clothes = {
  id: "clothes",
  name: {
    ua: 'Одяг та аксесуари',
    ru: 'Одежда и аксессуары',
    en: 'Clothing and accessories',
  },
  questions: [
    {
      type: ONE_ANSWER,
      text: {
        ua: 'Розмір одягу одержувача',
        ru: 'Размер одежды',
        en: "Recipient's clothes size",
      },
      answers: {
        ua: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'не знаю', { custom: 'свій варіант відповіді' }],
        ru: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'не знаю', { custom: 'cвой вариант ответа' }],
        en: ['XS', 'S', 'M', 'L', 'XL', 'XXL', "I don’t know", { custom: 'your answer' }],
      }
    },

    {
      type: TEXT_ANSWER,
      text: {
        ua: 'Розмір взуття',
        ru: 'Размер обуви',
        en: 'Size of shoes',
      },
    },

    {
      type: TEXT_ANSWER,
      text: {
        ua: 'Улюблений колір',
        ru: 'Любимый цвет',
        en: 'Favourite color',
      }
    },

    {
      type: ONE_ANSWER,
      text: {
        ua: 'Чи носить прикраси?',
        ru: 'Носит ли украшения?',
        en: 'Does the recipient wear jewelry?',
      },
      answers: {
        ua: ['так, сережки', 'так, ланцюжки',
             'так, каблучки', 'так, браслети',
             'так, крім біжутерії', 'ні',
             'не знаю', { custom: 'свій варіант відповіді' }],
        ru: ['да, сережки', 'да, цепочки',
             'да, кольца', 'да, браслеты',
             'да, кроме бижутерии', 'нет',
             'не знаю', { custom: 'cвой вариант ответа' }],
        en: ['yes, earrings', 'yes, chains',
             'yes, rings', 'yes, bracelets',
             'yes, but only jewelry made of precious metals and stones',
             'no', "I don’t know", { custom: 'your answer' }]
      }
    },

  ]
};

const sport = {
  id: "sport",
  name: {
    ua: 'Спорт',
    ru: 'Спорт',
    en: 'Sport',
  },
  questions: [
    {
      onlyOne: true,
      type: MULTIPLE_ANSWER,
      text: {
        ua: 'Улюблені види спорту',
        ru: 'Любимые виды спорта',
        en: 'Favourite sports',
      },
      answers: {
        ua: ['фітнес', 'йога', 'біг', 'тренажери', 'плавання', 'велоспорт', { custom: 'інше' }],
        ru: ['фитнес', 'йога', 'бег', 'тренажеры', 'плаванье', 'велоспорт', { custom: 'другое' }],
        en: ['fitness', 'yoga', 'running', 'trainers', 'swimming', 'cycling', { custom: 'other' }],
      }
    },
  ]
};

const healthyFood = {
  id: "healthyFood",
  name: {
    ua: 'Здорове харчування',
    ru: 'Здоровое питание',
    en: 'Healthy food',
  },
};

const homeDecor = {
  id: "homeDecor",
  name: {
    ua: 'Декор для дому',
    ru: 'Декор для дома',
    en: 'Home decor',
  },
};

const travel = {
  id: "travel",
  name: {
    ua: 'Подорожі',
    ru: 'Путешествия',
    en: 'Travel',
  },
};

const gardening = {
  id: "gardening",
  name: {
    ua: 'Садівництво',
    ru: 'Садоводство',
    en: 'Gardening',
  },
};

const cooking = {
  id: "cooking",
  name: {
    ua: 'Кулінарія',
    ru: 'Кулинария',
    en: 'Cooking',
  },
};

const baking = {
  id: "baking",
  name: {
    ua: 'Випічка',
    ru: 'Выпечка',
    en: 'Baking'
  },
};

const crochet = {
  id: "crochet",
  name: {
    ua: 'Плетіння гачком',
    ru: 'Вязание крючком',
    en: 'Crochet',
  },
};

const knitting = {
  id: "knitting",
  name: {
    ua: 'Плетіння спицями',
    ru: 'Вязание спицами',
    en: 'Knitting',
  },
};

const embroidery = {
  id: "embroidery",
  name: {
    ua: 'Вишивання',
    ru: 'Вышивание',
    en: 'Embroidery',
  },
};

const sweet = {
  id: "sweet",
  name: {
    ua: 'Солодощі',
    ru: 'Сладости',
    en: 'Sweetness',
  },
};

const drawing = {
  id: "drawing",
  name: {
    ua: 'Малювання',
    ru: 'Рисование',
    en: 'Drawing',
  },
  questions: [
    {
      onlyOne: true,
      type: TEXT_ANSWER,
      text: {
        ua: 'Улюблений вид малювання',
        ru: 'Любимый вид рисования',
        en: 'Favourite kind of drawing',
      }
    },
  ]
};

const drinks = {
  id: "drinks",
  name: {
    ua: 'Безалкогольні напої',
    ru: 'Безалкогольные напитки',
    en: 'Drinks',
  },
  questions: [
    {
      onlyOne: true,
      type: MULTIPLE_ANSWER,
      text: {
        ua: 'Улюблені безалкогольні напої',
        ru: 'Любимые безалкогольные напитки',
        en: 'Favourite drinks (non-alcoholic)',
      },
      answers: {
        ua: ['кава', 'чай', 'газовані напої', { custom: 'інше' }],
        ru: ['кофе', 'чай', 'газированные напитки', { custom: 'другое' }],
        en: ['coffee', 'tea', 'carbonated drinks', { custom: 'other' }],
      }
    },
  ]
};

const alcohol = {
  id: "alcohol",
  name: {
    ua: 'Алкогольні напої',
    ru: 'Алкогольные напитки',
    en: 'Alcoholic drinks',
  },
  questions: [
    {
      onlyOne: true,
      type: MULTIPLE_ANSWER,
      text: {
        ua: 'Улюблені алкогольні напої',
        ru: 'Любимые алкогольные напитки',
        en: 'Favourite drinks (alcoholic)',
      },
      answers: {
        ua: ['вино', 'горілка', 'пиво', 'віски', 'ром', 'коньяк', 'джин', 'лікер', { custom: 'інше' }],
        ru: ['вино', 'водка', 'пиво', 'виски', 'ром', 'коньяк', 'джин', 'ликёр', { custom: 'другое' }],
        en: ['wine', 'vodka', 'beer', 'whiskey', 'rum', 'cognac', 'gin', 'liquor', { custom: 'other' }],
      }
    },
  ]
};

export const questions = [
  malavChoice, cosmetics, clothes,
  sport, healthyFood, homeDecor,
  travel, gardening, cooking,
  baking, crochet, knitting,
  embroidery, sweet, drawing,
  drinks, alcohol,
];
