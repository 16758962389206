import Page from "./Page";
import Title from '../components/Title';
import styles from "./InfoPage.module.css";


const InfoPage = (props) => {
  const { title, text, children } = props;
  const inner = children ?
    <div className={styles.inner}>{children}</div> :
    <pre className={styles.pageInfo}>{text}</pre>;
  return (
    <Page>
      <div className={styles.container}>
        <Title text={title} />
        <div className={styles.content}>{inner}</div>
      </div>
    </Page>
  )
};

export default InfoPage;
