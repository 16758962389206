import { useState, useEffect, useId } from 'react';

import Page from './Page';
import Button from '../components/Button';
import Title from '../components/Title';
import UpTitleInput from '../components/UpTitleInput';
import TextAnswerQuestion from '../components/TextAnswerQuestion';
import AdminLoginPage from './AdminLoginPage';
import AdminLoadingPage from './AdminLoadingPage';
import styles from './AdminResourcesPage.module.css';
import { isValidNumber } from '../utils';

const resourceFetch = ({ urlSuffix = '', method = 'GET', body = null }) =>
  fetch('/api/resources' + urlSuffix, {
    body: body ? JSON.stringify(body) : null,
    method,
    headers: {
      'Content-Type': body ? 'application/json' : null,
      'Api-Key': localStorage.getItem('token')
    }
  });

const ERROR_MESSAGE = 'Помилка! Щось пішло не так';
const INVALID_ID_MESSAGE = 'Помилка! Id повинен складатися лише з латинських літер';
const CONFIRM_REMOVE_MESSAGE = 'Ви дійсно хочете видалити цей ресурс?';
const INVALID_PRICE_MESSAGE = 'Помилка! Ціна повинна бути корректним числом';


const Upload = (props) => {
  const { url } = props;
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState('');

  const handleFileChange = (event) => {
    if (event.target.files)
      setFile(event.target.files[0]);
  }

  const handleUpload = async () => {
    if (!file)
      return;
    if (file.size > 2097152) { // 2 Mb
      setStatus("File size limit is 2 Mb!");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    let data;
    let result;
    try {
      setStatus('Waiting...');
      result = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Api-Key': localStorage.getItem('token'),
          // 'Content-Type': 'multipart/form-data'
        }
      });
      if (result.ok)
        setStatus('OK! File was uploaded');
      else
        setStatus('Error uploading file');
    }
    catch (err) {
      console.log(err);
      data = await result.json();
      console.log(data);
      setStatus('Error uploading file');
    }
  }

  const id = useId();
  // <p>File: {file.name}</p>
  const statusStyle = (status && status.startsWith('OK')) ? styles.uploadSuccess : styles.uploadFailed;
  return (
    <>
      <input type="file" id={id} onChange={handleFileChange} />
      {file &&
        <div className={styles.uploadBlock}>
          <Button onClick={handleUpload}>Upload</Button>
          {
            status && <p className={statusStyle}>{status}</p>
          }
        </div>
      }
    </>
  );
}

const ImageResource = (props) => {
  const { id, onBack, onRemove } = props;
  const sex = id.toLowerCase().includes('women') ? 'women' : 'men';
  const url = `/api/resources/image/monthbox/${sex}`;

  const imgUrl = `/images/monthbox-${sex}.jpg`;
  return (
    <div className={styles.resourceContainer}>
      <Title text="Редагування ресурсу" />
      <div className={styles.buttonsContainer}>
        <Button onClick={onBack}><i className="fa-sharp fa-solid fa-long-arrow-left"></i></Button>
        <Button onClick={onRemove}><i className="fas fa-trash"></i></Button>
      </div>
      <p className={styles.resourceId}>Id</p>
      <p className={styles.resourceIdValue}>{id}</p>
      <Upload url={url} />
      <img className={styles.imageResource} src={imgUrl} />
    </div>
  );
}

const Resource = (props) => {
  const { resource, onBack } = props;
  const id = resource.id || '';
  // to do: make this input multiline
  const [value, setValue] = useState(resource.value || '');
  const [title, setTitle] = useState(resource.title || '');
  const [description, setDescription] = useState(resource.description || '');

  const onTitleChange = (value) => setTitle(value);
  const onDescriptionChange = (value) => setDescription(value);

  const onSave = () => {
    if (id.toLowerCase().includes('price') && !isValidNumber(value)) {
      alert(INVALID_PRICE_MESSAGE);
      return;
    }
    resourceFetch({
      method: 'PUT',
      body: { id, value, title, description }
    }).then(res => {
      if (res.ok) {
        onBack();
      }
      else
        alert(ERROR_MESSAGE);
    }).catch(() => alert(ERROR_MESSAGE));
  };

  const onRemove = () => {
    if (!window.confirm(CONFIRM_REMOVE_MESSAGE))
      return;
    resourceFetch({
      method: 'DELETE',
      urlSuffix: `/${id}`
    }).then(res => {
      if (res.ok) {
        onBack();
      }
      else
        alert(ERROR_MESSAGE);
    }).catch(() => alert(ERROR_MESSAGE));
  }

  if (id.toLowerCase().includes('image'))
    return (
      <ImageResource id={id} onBack={onBack} onRemove={onRemove} />
    );

  return (
    <div className={styles.resourceContainer}>
      <Title text="Редагування ресурсу" />
      <div className={styles.buttonsContainer}>
        <Button onClick={onBack}><i className="fa-sharp fa-solid fa-long-arrow-left"></i></Button>
        <Button onClick={onSave}><i class="fas fa-save"></i></Button>
        <Button onClick={onRemove}><i class="fas fa-trash"></i></Button>
      </div>
      <p className={styles.resourceId}>Id</p>
      <p className={styles.resourceIdValue}>{id}</p>
      <p className={styles.resourceId}>Значення / текст</p>
      <textarea
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
      <TextAnswerQuestion text={"Заголовок (необов'язково!)"} answerText={title} onChange={onTitleChange} />
      <TextAnswerQuestion text={"Опис (необов'язково!)"} answerText={description} onChange={onDescriptionChange} />
    </div>
  );
}

const AdminResourcesPage = (props) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loading, setLoading] = useState(null);
  const [resources, setResources] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    localStorage.setItem('token', token);
    if (token === '' || token == null)
      setResources(null);
    (async () => {
      setLoading(true);
      const resp = await resourceFetch({ method: 'GET' });
      if (resp.ok) {
        setResources(await resp.json());
      }
      else {
        setResources(null);
        localStorage.removeItem(token);
      }
      setLoading(false);
    })();
  }, [token]);

  const resourcesJSX = resources && resources.map((res, index) => {
    const { id, value, title, description } = res;
    const short = (value, len) => {
      const str = value || '';
      return str.substring(0, len) + (str.length <= len ? '' : '...');
    }
    const onClick = () => {
      setSelectedIndex(index);
    };
    return (
      <tr key={id} className={styles.tableRow} onClick={onClick}>
        <td>{id}</td>
        <td>{short(value, 16)}</td>
        <td>{short(title, 40)}</td>
      </tr>
    );
  });

  const [newId, setNewId] = useState('');
  const onIdChange = (event) => setNewId(event.target.value);
  const resourceFromId = (id) => ({ id, value: '', title: '', description: '' });
  const isValidId = (idStr) => !!idStr.match(/^[a-z]+$/i);
  const onAddClick = () => {
    if (!isValidId(newId)) {
      alert(INVALID_ID_MESSAGE);
      return;
    }
    resourceFetch({
      method: 'POST',
      body: resourceFromId(newId)
    }).then(res => {
      if (res.ok) {
        setResources(r => [...r, { id: newId }]);
        setNewId('');
      }
      else
        alert(ERROR_MESSAGE);
    });
  };
  if (loading === false && resources == null)
    return (
      <AdminLoginPage tokenCallback={setToken} />
    );
  if (loading)
    return (
      <AdminLoadingPage />
    );

  if (typeof selectedIndex === 'number')
    return (
      <Page>
        <Resource resource={resources[selectedIndex]}
                  onBack={() => setSelectedIndex(null)} />
      </Page>
    );

  return (
    <Page>
      <Title text="Редагування ресурсів" />
      <div className={styles.addContainer}>
        <Button className={styles.addButton} onClick={onAddClick}>Додати ресурс</Button>
        <UpTitleInput value={newId}
                      placeholder={"Id, англійською, без пробілів"}
                      onChange={onIdChange} />
      </div>
      <p className={styles.hint}>Натисніть на ресурс для його редагування</p>
      <table className={styles.resourcesTable}>
        <thead>
          <tr className={styles.tableRow}>
            <th>Id</th>
            <th>Значення / текст</th>
            <th>Заголовок / опис</th>
          </tr>
        </thead>
        <tbody>
          {resourcesJSX}
        </tbody>
      </table>
    </Page>
  );
}

export default AdminResourcesPage;
