// import img1 from '../assets/main-photo.jpg';
import img1 from '../assets/202.jpg';
import img2 from '../assets/197.jpg';
import styles from './Main.module.css';
import Page from "./Page";
import Link from '../components/Link';
import { useTranslation } from 'react-i18next';

const Main = (props) => {
  const { t } = useTranslation();
  return (
    <Page>
      <div className={styles.imgContainer}>
        {/* <img src={img1} alt="Guy gives his girl giftbox"
             className={styles.imgMain} /> */}
        <div className={styles.mainText}>
          <h1 className={styles.companyName}>MaLaV</h1>
          <h1>{t('slogan')}</h1>
          <p>{t('giftbox-mission-shortly')}</p>
          <div className={styles.buttons}>
            <Link to="/order">{t('order-btn')}</Link>
          </div>
        </div>
      </div>
      <div className={styles.surprise}>
        <p>{t('surprise-price-1')}</p>
        <p>{t('surprise-price-2')}</p>
      </div>
      <div className={styles.secondContainer}>
        {/* <img className={styles.img2} src={img2} /> */}
        <div className={styles.innerContainer}>
          <h1>{t('about-us-title')}</h1>
          <p>{t('about-us')}</p>
          <div className={styles.buttons}>
            <Link className={styles.detailsBtn} to="/about">{t('who-are-we')}</Link>
            <Link className={styles.detailsBtn} to="/detailed">{t('detailed')}</Link>
          </div>
        </div>
      </div>
    </Page>
  )

};

export default Main;
