import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import App from './App';
import { store } from './store/store'
import resources from './resources/resources';

const lng = localStorage.getItem('lang') || 'ua';
localStorage.setItem('lang', lng);
i18n.use(initReactI18next)
    .init({ resources, lng });

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
