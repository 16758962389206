import { Link as NavLink } from 'react-router-dom';
import styles from './Link.module.css';

const Link = (props) => {
  const className = `${styles.link} ${props.className}`;
  return (
    <NavLink to={props.to} className={className} onClick={() => window.scroll(0, 0)}>
      {props.children}
    </NavLink>
  );
};

export default Link;
