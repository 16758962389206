import InfoPage from "./InfoPage";
import { useTranslation } from 'react-i18next';
import styles from "./Terms.module.css";

// const general =
// `MaLaV is a Danish online web shop which is located at https://malav.group/.
// The web shop is provided to you by: MaLaV I/S Sønderbrogade 48, 8700 Horsens, Denmark
// Tel.: +4542609106
// E-mail: malav.business.dk@gmail.com
// CVR: 42824089
// `;

const HowToShop = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.pageInfo}>
      <h2>{t('terms-how-to-shop-title')}</h2>
      <p>{t('terms-choose-box')}</p><br></br>
      <p>{t('terms-month-box')}</p>
      <ol>
        <li>{t('terms-month-box-item-1')}</li>
        <li>{t('terms-month-box-item-2')}</li>
        <li>{t('terms-month-box-item-3')}</li>
        <li>{t('terms-month-box-item-4')}</li>
      </ol><br></br>
      <p>{t('terms-surprise-box')}</p>
      <ol>
        <li>{t('terms-surprise-box-item-a')}</li>
        <li>{t('terms-surprise-box-item-b')}</li>
      </ol>
    </div>
  );
};

// const checkout =
// `At the checkout there is a form to fill out with your personal information (name, address etc.).
// Once that is done, you are to choose a payment method.
// Once this has been done, you click the button “Checkout” to go through to the payment.
// When you have completed the payment process, your order is completed, and you will receive an order confirmation by e-mail.
// `;

const Prices = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h2>{t('terms-prices-title')}</h2>
      <p>{t('terms-price-month-box')}</p>
      <p>{t('terms-price-surprise-box')}</p>
      {/* There is a choice:
      <ul>
        <li>fixed retail price for the “ready-made” boxes with visible content (VAT inclusive)</li>
        <li>price, which customer chooses by himself for the box with content (VAT inclusive), consisting of the items from the chosen categories</li>
      </ul> */}
    </div>
  )
};

const Payment = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h2>{t('terms-payment-title')}</h2>
      <p>{t('terms-payment-info')}</p>
    </div>
  );
};

// const Div = (props) => {
//   return (
//     <div className={styles.pageInfo}>
//       {props.children}
//     </div>
//   );
// }

// const Pre = (props) => {
//   return (
//     <p className={styles.pageInfo}>
//       {props.children}
//     </p>
//   );
// };


const Terms = (props) => {
  const { t } = useTranslation();
  return (
    <InfoPage title={t('terms')}>
      <div className={styles.content}>
        <p>{t('terms-contact-info')}</p>
        {/* <Pre>{general}</Pre> */}
        <HowToShop />
        {/* <Pre>{checkout}</Pre> */}
        <Prices />
        <Payment />
      </div>
    </InfoPage>
  );
};

export default Terms;
