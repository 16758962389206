import { useTranslation } from 'react-i18next';

import Page from './Page';
import Title from '../components/Title';
import Link from '../components/Link';
import styles from './OrderMonthBoxFor.module.css';

const OrderMonthBoxFor = (props) => {
  const { t } = useTranslation();

  return (
    <Page>
      <div className={styles.container}>
        <Title text={t('order-month-box')} />
        <div className={styles.linksContainer}>
          <Link to="/order/month/men">{t('for-men')}</Link>
          <Link to="/order/month/women">{t('for-women')}</Link>
        </div>
      </div>
    </Page>
  );
};

export default OrderMonthBoxFor;
