import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import OneAnswerQuestion from "../components/OneAnswerQuestion";
import { chooseDelivery, selectDeliveryInfo } from "../store/quizSlice";

const ChooseDelivery = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let { currency, deliveryIndex, deliveriesPrices } = useSelector(selectDeliveryInfo);
  currency = currency.toUpperCase();
  const deliveryOptions = deliveriesPrices.map(dp => `${t(dp.text)}: ${dp.price} ${currency}`);
  const onDeliveryChange = (index) => {
    dispatch(chooseDelivery({
      deliveryIndex: index,
    }));
  };

  return (
    <OneAnswerQuestion text={t('delivery')}
                       options={deliveryOptions}
                       selected={deliveryIndex}
                       onChange={onDeliveryChange}
                       inputName='delivery' />
  );
};

export default ChooseDelivery;
