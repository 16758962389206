import { useSelector, useDispatch } from 'react-redux';

import Range from '../components/Range';
import { choosePrice, selectPriceInfo } from '../store/quizSlice';

const ChoosePrice = () => {
  const dispatch = useDispatch();

  const priceInfo = useSelector(selectPriceInfo);
  const currency = priceInfo.currency.toUpperCase();
  const onPriceChange = (value) => {
    dispatch(choosePrice({
      price: value
    }));
  };

  return (
    <Range step={priceInfo.step}
           min={priceInfo.minPrice} max={priceInfo.maxPrice} value={priceInfo.giftPrice}
           leftLabel={`${priceInfo.minPrice} ${currency}`}
           rightLabel={`${priceInfo.maxPrice} ${currency}`}
           onChange={onPriceChange} />
  );
};

export default ChoosePrice;
