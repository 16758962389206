import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Page from "./Page";
import styles from './ThankYouPage.module.css';
import boxImg from '../assets/check-white.png';

const ThankYouPage = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  return (
    <Page >
      <div className={styles.container}>
        {/* <img className={styles.box} src={boxImg} /> */}
        <div className={styles.message}>
          <img src={boxImg} />
          <h1>{t('order-success-1')}</h1>
          <p>{t('order-success-2')}</p>
          <p>Id: {id}</p>
        </div>
      </div>
    </Page>
  );
};

export default ThankYouPage;
