import { useState, useEffect } from 'react';
import styles from './AdminPage.module.css';


import Title from '../components/Title';
import Page from '../pages/Page';
import ShowOrder from '../components/ShowOrder';
import Button from '../components/Button';
import AdminLoginPage from './AdminLoginPage';
import AdminLoadingPage from './AdminLoadingPage';

const OrderLink = (props) => {
  const { order, onRemove } = props;
  const [orderStatus, setOrderStatus] = useState(order.status);
  const processOrder = () => setOrderStatus('Processed');
  const [open, setOpen] = useState(false);
  const onClick = () => {
    setOpen(o => !o);
  };
  const plusMinus = open ? '-' : '+';
  const orderHeaderClass = orderStatus === 'Processed' ? styles.done : '' ;
  return (
    <div className={styles.orderLink}>
      <h4 className={styles.link} onClick={onClick}>
        {plusMinus}
        <span className={orderHeaderClass}>Order {order.id}</span>
      </h4>
      {
        open &&
        <ShowOrder order={order}
                   orderStatus={orderStatus}
                   onRemove={onRemove}
                   onProcess={processOrder} />
      } </div>
  );
}


const fetchOrders = async (token) => {
  if (!token)
    return;
  const response = await fetch('/api/payments', {
    headers: {
      'Api-Key': token
    },
  });
  if (!response.ok)
    return;
  const fetchedOrders = await response.json();
  fetchedOrders.sort((a, b) => b.id - a.id);
  return fetchedOrders;
};

const AdminPage = (props) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loading, setLoading] = useState(null);
  const [orders, setOrders] = useState(null);

  const logoutClick = () => {
    setToken('');
  };

  const LogoutButton = () =>
    <Button className={styles.logout} onClick={logoutClick}>Logout</Button>;

  const removeOrderById = (id) => setOrders(orders => orders.filter(o => o.id !== id));

  useEffect(() => {
    localStorage.setItem('token', token);
    if (token === '' || token == null)
      setOrders(null);
    (async () => {
      setLoading(true);
      const result = await fetchOrders(token);
      if (!result) {
        setToken(null);
        setLoading(false);
        localStorage.removeItem(token);
        return;
      }
      setOrders(result);
      setLoading(false);
    })();
  }, [token]);

  if (loading === false && orders == null)
    return (
      <AdminLoginPage tokenCallback={setToken} />
    );
  if (loading)
    return (
      <AdminLoadingPage />
    );

  return (
    <Page>
      <Title text="Список замовлень" />
      <LogoutButton />
      {
        orders && orders.map(order => (
          <OrderLink key={order.id} order={order} onRemove={removeOrderById} />
        ))
      }
    </Page>
  );
};

export default AdminPage;
