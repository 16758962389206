import { configureStore } from "@reduxjs/toolkit";
import quizReducer from './quizSlice';
import resourcesReducer from './resourcesSlice';

export const store = configureStore({
  reducer: {
    quiz: quizReducer,
    resources: resourcesReducer
  }
});
