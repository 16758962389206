import styles from './ShowAnswers.module.css';
import { useTranslation } from 'react-i18next';

const ShowAnswers = (props) => {
  const { answers } = props;
  const { t } = useTranslation();

  return answers.map((answerCategory, index) => {
    const qas = answerCategory.questionsAnswers;
    const noQuestions = qas == null || qas.length === 0;
    return (
      <div className={styles.answers} key={index}>
        <h3 className={styles.category}>{answerCategory.categoryName}</h3>
        {noQuestions && <p className={styles.noQuestions}>{t('no-answers-category')}</p>}
        {
          answerCategory.questionsAnswers.map((qa, qi) => (
            <div className={styles.qa} key={qi}>
              <h4>{qa.question}</h4> {qa.answer}
            </div>
          ))
        }
      </div>
    );
  });
};

export default ShowAnswers;
