import RadioButton from './RadioButton';

const OneAnswerQuestion = (props) => {
  const { text, onlyOne, options, selected, onChange, inputName } = props;
  const last = options.length - 1;
  const radioButtons = options.map((option, index) => {
    const onSelect = (value) => {
      if (!value && index === last && typeof option === 'object') {
        // console.log('!value && index === last');
        onChange('');
      }
      else
      if (typeof value === 'string') {
        // console.log('value');
        onChange(value);
      }
      else {
        // console.log('else');
        onChange(index);
      }
    };
    const checked = index === selected ||
                    (index === last && typeof selected === 'string');
    const text = typeof option === 'string' ? option : option.custom;
    const onTextChange = (event) => onSelect(event.target.value);
    return (
      <div key={index.toString()}>
        <RadioButton text={text}
                     inputName={inputName}
                     checked={checked}
                     onChange={onSelect} />
        {
          index === last && checked &&
          typeof selected === 'string' &&
          <input type="text" value={selected} onChange={onTextChange} />
        }
      </div>
    );
  });

  return (
    <>
      {
        onlyOne ?
          <h1>{text}</h1> :
          <p className={'question-text'}>{text}</p>
      }
      <div>
        {radioButtons}
      </div>
    </>
  );
};

export default OneAnswerQuestion;
