const deliveryPricesObj = () => ({
  'deliverySelf': 0,
  'deliveryDenmark': 0,
  'deliveryUkraine': 0
});

const boxPricesObj = () => ({
  'boxDefault': 0,
  'boxBranded': 0
});

export const pricesCategories = {
  dkk: {
    name: 'DKK',
    minPrice: 250,
    maxPrice: 2050,
    step: 50,
    categoriesCount: [
      { price: 250, count: 3 },
      { price: 750, count: 5 },
      { price: 1250, count: 6 },
      { price: 1750, count: 7 },
      { price: 2050, count: 9 },
    ],
    defaultPrice: 1250,
    defaultCategoriesCount: 6,
    delivery: deliveryPricesObj(),
    box: boxPricesObj(),
    monthBoxMen: 0,
    monthBoxWomen: 0,
  },
  eur: {
    name: 'EUR',
    minPrice: 35,
    maxPrice: 280,
    step: 5,
    categoriesCount: [
      { price: 35, count: 3 },
      { price: 105, count: 5 },
      { price: 185, count: 6 },
      { price: 255, count: 7 },
      { price: 280, count: 9 },
    ],
    defaultPrice: 185,
    defaultCategoriesCount: 6,
    delivery: deliveryPricesObj(),
    box: boxPricesObj(),
    monthBoxMen: 0,
    monthBoxWomen: 0,
  },
  uah: {
    name: 'UAH',
    minPrice: 1200,
    maxPrice: 10000,
    step: 100,
    categoriesCount: [
      { price: 1200, count: 3 },
      { price: 3600, count: 5 },
      { price: 6000, count: 6 },
      { price: 8400, count: 7 },
      { price: 10000, count: 9 },
    ],
    defaultPrice: 6000,
    defaultCategoriesCount: 6,
    delivery: deliveryPricesObj(),
    box: boxPricesObj(),
    monthBoxMen: 0,
    monthBoxWomen: 0,
  },
};
