import InfoPage from "./InfoPage";
import Title from '../components/Title';
import { useTranslation } from 'react-i18next';

const Guarantees = (props) => {
  const { t } = useTranslation();
  return (
    <InfoPage title={t('menu-guarantees')}>
      <p>{t('menu-guarantees-info')}</p>
    </InfoPage>
  );
};

export default Guarantees;
