import InfoPage from "./InfoPage";
import { useTranslation } from 'react-i18next';

// const text =
// `When placing an order at our web shop, you have to state the following personal data about yourself/recepient: full name, a billing address, a delivery address, phone number.

// This personal data is registered at MaLaV where it is saved and stored for 5 years. We solely register your/recepient personal data in order to be able to deliver your order. Whenever we collect personal data from you on our website, we make sure that it always happens in agreement with you. Your data is transmitted to https://malav.group/ non-encrypted. Your personal data is stored safely and confidentially with us, and no information is passed on to any third parties. We do not store your data encrypted, but we store it safely on computers which are protected by passwords.

// As a registered customer at MaLaV you always have the right to protest against the registration of your data and to know what data we have registered about you. You have these rights according to the Danish Act on Processing of Personal Data, and any inquiries about this right should be addressed to MaLaV by e-mail at: malav.business.dk@gmail.com.
// `;

const Terms = (props) => {
  const { t } = useTranslation();
  return (
    <InfoPage title={t('privacy-policy')}>
      <p>{t('privacy-policy-info')}</p>
    </InfoPage>
  );
};

export default Terms;

