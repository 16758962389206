import { ONE_ANSWER, MULTIPLE_ANSWER, TEXT_ANSWER } from "./questionTypes";

export const testCategory = {
  id: 'testCategory',
  name: {
    ua: 'Test Category',
    ru: 'Test Category',
    en: 'Test Category',
  },
  hideTitle: true,
  questions: [
    {
      type: ONE_ANSWER,
      text: {
        ua: 'One answer question, without custom',
        ru: 'One answer question, without custom',
        en: 'One answer question, without custom',
      },
      answers: {
        ua: ['one answer 1', 'one answer 2', 'one answer 3', 'one answer 4'],
        ru: ['one answer 1', 'one answer 2', 'one answer 3', 'one answer 4'],
        en: ['one answer 1', 'one answer 2', 'one answer 3', 'one answer 4'],
      }
    },
    {
      type: ONE_ANSWER,
      text: {
        ua: 'One answer question, with custom',
        ru: 'One answer question, with custom',
        en: 'One answer question, with custom',
      },
      answers: {
        ua: ['one answer 1', 'one answer 2', 'one answer 3', { custom: 'custom' }],
        ru: ['one answer 1', 'one answer 2', 'one answer 3', { custom: 'custom' }],
        en: ['one answer 1', 'one answer 2', 'one answer 3', { custom: 'custom' }],
      }
    },
    {
      type: MULTIPLE_ANSWER,
      text: {
        ua: 'Multiple answer question, without custom',
        ru: 'Multiple answer question, without custom',
        en: 'Multiple answer question, without custom',
      },
      answers: {
        ua: ['multiple answer 1', 'multiple answer 2', 'multiple answer 3', 'multiple answer 4'],
        ru: ['multiple answer 1', 'multiple answer 2', 'multiple answer 3', 'multiple answer 4'],
        en: ['multiple answer 1', 'multiple answer 2', 'multiple answer 3', 'multiple answer 4'],
      }
    },
    {
      type: MULTIPLE_ANSWER,
      text: {
        ua: 'Multiple answer question, with custom',
        ru: 'Multiple answer question, with custom',
        en: 'Multiple answer question, with custom',
      },
      answers: {
        ua: ['multiple answer 1', 'multiple answer 2', 'multiple answer 3', { custom: 'multiple custom' }],
        ru: ['multiple answer 1', 'multiple answer 2', 'multiple answer 3', { custom: 'multiple custom' }],
        en: ['multiple answer 1', 'multiple answer 2', 'multiple answer 3', { custom: 'multiple custom' }],
      }
    },
    {
      type: TEXT_ANSWER,
      text: {
        ua: 'Text answer question',
        ru: 'Text answer question',
        en: 'Text answer question',
      },
    }
  ]
};
