import { useState } from "react";
import { useTranslation } from "react-i18next";

import Page from "./Page";
import Title from "../components/Title";
import Button from "../components/Button";
import Input from "../components/UpTitleInput";
import { isEmailValid, isEmptyStr, onPhoneKeyDown } from "../utils.js";

import styles from "./Contacts.module.css";

const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const clear = () => setValue('');

  return {
    value,
    clear,
    onChange: handleChange,
  };
};

const IframeMap = (props) => {
  return (
    <iframe
      title="mapiframe"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d279.91424303664087!2d9.83822801468048!3d55.857219300000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464c631d2454ece5%3A0x3ed33cb03b0547d1!2zU8O4bmRlcmJyb2dhZGUgNDgsIDg3MDAgSG9yc2Vucywg0JTQsNC90LjRjw!5e0!3m2!1sru!2sua!4v1662064379567!5m2!1sen!2sua"
      height="450"
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  );
};

const Contacts = (props) => {
  const { t } = useTranslation();
  const name = useInput("");
  const email = useInput("");
  const phone = useInput("");
  const message = useInput("");

  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);

  const send = () => {
    const obj = {
      name: name.value,
      email: email.value,
      phone: phone.value,
      message: message.value,
    };
    const empty = Object.values(obj).some(isEmptyStr);
    if (empty) {
      setError("fill-all-forms");
      return;
    }
    if (!isEmailValid(obj.email)) {
      setError("validation-email-invalid");
      return;
    }
    setPending(true);
    const url = "/api/feedback";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) setError("feedback-failed");
        else {
          setError(false);
          name.clear();
          email.clear();
          phone.clear();
          message.clear();
        }
        setPending(false);
      })
      .catch((err) => {
        setError("feedback-failed");
        setPending(false);
      });
  };


  return (
    <Page>
      <Title text={t("contacts")} />
      <div className={styles.container}>
        <div className={styles.col}>
          <div className={styles.info}>
            <p>Sønderbrogade 48, 8700 Horsens</p>
            <p>malav.business.dk@gmail.com</p>
            <p>+45 42 60 91 06</p>
            <div className={styles.socialButtons}>
              <a href='https://www.facebook.com/profile.php?id=100089281557202' className={styles.facebook}></a>
              <a href='https://www.instagram.com/malav_surprise/' className={styles.instagram}></a>
              <a href='/' className={styles.youtube}></a>
            </div>
          </div>
          <IframeMap />
        </div>

        <div className={styles.col}>
          <h1>{t("send-email")}</h1>
          {
            pending && <p className={styles.pending}>{t('order-waiting')}</p>
          }
          {
            error && !pending && <p className={styles.errorStyle}>{t(error)}</p>
          }
          {
            error === false && !pending && <p className={styles.messageStyle}>{t('feedback-success')}</p>
          }
          <Input
            placeholder={t("name")}
            onChange={name.onChange}
            value={name.value}
          />
          <Input
            placeholder={t("email")}
            onChange={email.onChange}
            value={email.value}
          />
          <Input
            placeholder={t("phone")}
            onChange={phone.onChange}
            onKeyDown={onPhoneKeyDown}
            value={phone.value}
          />
          <textarea
            placeholder={t("message")}
            onChange={message.onChange}
            value={message.value}
          />
          <Button onClick={send}>
            <i className="fa fa-envelope"></i> {t("send")}
          </Button>
        </div>
      </div>
    </Page>
  );
};

export default Contacts;
