import { createSlice } from "@reduxjs/toolkit";

import {
  ONE_ANSWER,
  MULTIPLE_ANSWER,
  TEXT_ANSWER,
  RANGE_ANSWER,
  CHILDREN_AGE_ANSWER,
  DELIVERY_ANSWER
} from "./questionTypes";
import { questions } from "./questions";
import { childrenQuestions } from "./childrenQuestions";
import {
  requiredQuestions,
  requiredQuestionsChildren,
  requiredQuestionsMonthBox
} from "./requiredQuestions";
import { testCategory } from "./testCategory";
import { pricesCategories } from "./pricesCategories";
import { capitalize, isEmailValid, isEmptyStr, numToTextAge } from "../utils";

const defaultSelected = questions.map((_) => false);
const malavChoiceSelected = [true, ...defaultSelected.slice(1)];

const currencies = ['dkk', 'eur', 'uah'];
const boxTypes = ['boxDefault', 'boxBranded'];
const deliveryTypes = ['deliverySelf', 'deliveryDenmark', 'deliveryUkraine'];

const defaultCurrency = currencies[0];
const defaultBoxType = boxTypes[0];
const defaultDeliveryType = deliveryTypes[0];

const initialState = {
  type: null,
  allCategories: questions,
  selected: defaultSelected,
  categoriesAnswers: [],
  pricesCategories,
  boxType: defaultBoxType,
  deliveryType: defaultDeliveryType,
  deliveryAnswer: '',
  priceInfo: {
    giftPrice: pricesCategories[defaultCurrency].defaultPrice,
    currency: defaultCurrency,
    step: pricesCategories[defaultCurrency].step,
    categoriesAllowed: pricesCategories[defaultCurrency].defaultCategoriesCount,
    categoriesMax: 9,
    minPrice: pricesCategories[defaultCurrency].minPrice,
    maxPrice: pricesCategories[defaultCurrency].maxPrice,
    error: null,
  },
};

// questionCategory = {
//   name: {
//     ua, ru, en
//   },
//   questions: array of {
//     text: {
//       ua, ru, en
//     },
//     answers: {
//       ua, ru, en
//     }
//   }
// }

const defaultAnswer = (question) => {
  if (question.type === ONE_ANSWER) return -1;
  if (question.type === MULTIPLE_ANSWER)
    return question.answers.ua.map((_) => false);
  if (question.type === RANGE_ANSWER) {
    const { min, max, step } = question;
    const half = Math.floor((max - min) / step / 2);
    return min + half * step;
  }
  // number from 1 to 27
  // 1-11 = month
  // 12 = 1 year, 13 = 2 years, 14 = 3 years
  if (question.type === CHILDREN_AGE_ANSWER)
    return 16;
  // TEXT_ANSWER, DELIVERY_ANSWER
  return "";
};

const NO_ANSWER = 'validation-no-answer';
const NO_CHOSEN_ANSWER = 'validation-no-chosen-answer';
const INVALID_EMAIL = 'validation-email-invalid';

const validationError = (questionAnswer) => {
  const { question, answer } = questionAnswer;
  const { type } = question;
  // always null if question.type
  // is RANGE_ANSWER or CHILDREN_AGE_ANSWER
  let result = null;
  if (type === ONE_ANSWER) {
    const noAnswer = answer === -1 || isEmptyStr(answer);
    result = noAnswer ? NO_CHOSEN_ANSWER : null;
  }
  else if (type === MULTIPLE_ANSWER) {
    const noAnswer = answer.every(x => x === false) ||
                     answer.some(isEmptyStr);
    result = noAnswer ? NO_CHOSEN_ANSWER : null;
  }
  // TEXT_ANSWER
  else if (type === TEXT_ANSWER) {
    const noAnswer = answer == null || isEmptyStr(answer);
    if (noAnswer)
      result = NO_ANSWER;
    else if (question.text.en.startsWith('E-mail') && !isEmailValid(answer))
      result = INVALID_EMAIL;
  }
  // DELIVERY_ANSWER
  else if (type === DELIVERY_ANSWER) {
    if (question.delivery !== 'deliverySelf' && isEmptyStr(answer))
      result = NO_ANSWER;
  }
  return result;
}

const defaultValidationError = (question) => {
  const { type } = question;
  if ([RANGE_ANSWER, CHILDREN_AGE_ANSWER].includes(type))
    return null;
  if ([ONE_ANSWER, MULTIPLE_ANSWER].includes(type))
    return NO_CHOSEN_ANSWER;
  if (type === DELIVERY_ANSWER && question.delivery === 'deliverySelf')
    return null;
  // TEXT_ANSWER, DELIVERY_ANSWER (when not deliverySelf)
  return NO_ANSWER;
};

const createQuestionAnswer = (question) => ({
  question,
  answer: defaultAnswer(question),
  validationError: defaultValidationError(question),
});

const defaultInvalidAnswersCount = (category) =>
  category.questions.filter(q => defaultValidationError(q) !== null).length;

const createAnswersFromQuestions = (selectedCategories) => {
  return selectedCategories.map((category) => ({
    id: category.id,
    name: category.name,
    hideTitle: category.hideTitle,
    invalidAnswersCount: category.questions ? defaultInvalidAnswersCount(category) : 0,
    questionsAnswers: category.questions
      ? category.questions.map(createQuestionAnswer)
      : [],
  }));
};

const restoreDeliveryAnswer = (state, deliveryType, deliveryAnswer) => {
  for (const category of state.categoriesAnswers) {
    if (!category.id.startsWith('required'))
      continue;
    const deliveryQuestionAnswer = category.questionsAnswers.filter(qa => qa.question.type === DELIVERY_ANSWER)[0];
    deliveryQuestionAnswer.answer = deliveryAnswer;
    deliveryQuestionAnswer.question = {
      ...deliveryQuestionAnswer.question,
      delivery: deliveryType
    };
    if (deliveryAnswer === '' && deliveryType !== 'deliverySelf') {
      deliveryQuestionAnswer.validationError = NO_ANSWER;
      category.invalidAnswersCount++;
    }
  }
};

// TO DO: think about name, orderQuizSlice maybe ?
export const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    setPrices: (state, action) => {
      const { resources } = action.payload;
      for (const resource of resources) {
        const { id, value } = resource;
        if (!id.includes('Price'))
          continue;
        const price = Number(value);
        // monthBoxMenPriceEur -> eur
        const currency = id.substring(id.length - 3, id.length).toLowerCase();
        // monthBoxMenPriceEur -> monthBoxMen
        const priceItem = id.substring(0, id.indexOf('Price'));
        if (id.startsWith('monthBox'))
          state.pricesCategories[currency][priceItem] = price;
        else if (id.startsWith('box'))
          state.pricesCategories[currency].box[priceItem] = price;
        else if (id.startsWith('delivery'))
          state.pricesCategories[currency].delivery[priceItem] = price;
      }
    },

    clearCategories: (state, action) => {
      state.selected = defaultSelected;
    },

    chooseCurrency: (state, action) => {
      const { currencyIndex } = action.payload;
      const currency = currencies[currencyIndex];
      state.priceInfo.currency = currency;

      // price for month box is fixed
      if (state.type.startsWith('monthBox')) {
        // state.pricesCategories.dkk.monthBoxMen / Women
        state.priceInfo.giftPrice = state.pricesCategories[currency][state.type];
        return;
      }

      // price for surprise box is dynamic
      // and affects number of available categories
      const selectedCategoriesCount = state.selected.filter((s) => s).length;
      state.priceInfo.minPrice = state.pricesCategories[currency].minPrice;
      state.priceInfo.maxPrice = state.pricesCategories[currency].maxPrice;
      state.priceInfo.step = state.pricesCategories[currency].step;
      state.priceInfo.categoriesAllowed =
        state.pricesCategories[currency].defaultCategoriesCount;
      state.priceInfo.giftPrice = state.pricesCategories[currency].defaultPrice;
      if (selectedCategoriesCount > state.priceInfo.categoriesAllowed)
        state.selected = defaultSelected;
    },

    choosePrice: (state, action) => {
      const { price } = action.payload;
      const selectedCategoriesCount = state.selected.filter(s => s).length;
      state.priceInfo.giftPrice = price;
      const currency = state.priceInfo.currency;
      const { categoriesCount } = state.pricesCategories[currency];
      for (let i = categoriesCount.length - 1; i >= 0; i--)
        if (categoriesCount[i].price <= price) {
          state.priceInfo.categoriesAllowed = categoriesCount[i].count;
          break;
        }
      if (selectedCategoriesCount > state.priceInfo.categoriesAllowed)
        state.selected = defaultSelected;
    },

    chooseDelivery: (state, action) => {
      const { deliveryIndex } = action.payload;
      const deliveryType = deliveryTypes[deliveryIndex];
      state.deliveryType = deliveryType;
      state.deliveryAnswer = '';
      restoreDeliveryAnswer(state, deliveryType, '');
    },

    chooseBoxType: (state, action) => {
      const { boxIndex } = action.payload;
      const boxType = boxTypes[boxIndex];
      state.boxType = boxType;
    },

    setNoCategoriesError: (state, action) => {
      state.priceInfo.error = "no-categories";
    },

    chooseCategories: (state, action) => {
      state.priceInfo.error = null;
      let { selected } = action.payload;
      if (selected[0]) {
        selected = malavChoiceSelected;
      }
      const selectedCount = selected.filter((s) => s).length;
      if (selectedCount > state.priceInfo.categoriesMax) {
        state.priceInfo.error = "max-categories";
        return;
      }
      if (selectedCount > state.priceInfo.categoriesAllowed) {
        state.priceInfo.error = "extra-category";
        return;
      }
      state.selected = selected;
      const filteredCategories = state.allCategories.filter(
        (_, i) => selected[i]
      );
      const selectedCategories = filteredCategories.concat(requiredQuestions);
      state.categoriesAnswers = createAnswersFromQuestions(selectedCategories);

      restoreDeliveryAnswer(state, state.deliveryType, state.deliveryAnswer);
    },

    chooseSurpriseAdult: (state, action) => {
      const surpriseFor = capitalize(action.payload.for);
      state.type = 'surprise' + surpriseFor;
      const selectedCategories = [];
      state.selected = defaultSelected;
      state.categoriesAnswers = createAnswersFromQuestions(selectedCategories);
      restoreDeliveryAnswer(state, state.deliveryType, state.deliveryAnswer);
    },

    chooseSurpriseChildren: (state, action) => {
      state.type = 'surpriseChildren';
      const selectedCategories = [childrenQuestions, requiredQuestionsChildren];
      state.categoriesAnswers = createAnswersFromQuestions(selectedCategories);
      restoreDeliveryAnswer(state, state.deliveryType, state.deliveryAnswer);
    },

    chooseTestCategory: (state, action) => {
      state.type = 'testing';
      const selectedCategories = [testCategory];
      state.categoriesAnswers = createAnswersFromQuestions(selectedCategories);
      restoreDeliveryAnswer(state, state.deliveryType, state.deliveryAnswer);
    },

    chooseMonthBox: (state, action) => {
      const { surpriseFor } = action.payload;
      const type = 'monthBox' + capitalize(surpriseFor);
      state.type = type;
      const selectedCategories = [requiredQuestionsMonthBox];
      const { currency } = state.priceInfo;
      // smth like state.pricesCategories['dkk']['monthBoxWomen']
      state.priceInfo.giftPrice = state.pricesCategories[currency][type];
      state.categoriesAnswers = createAnswersFromQuestions(selectedCategories);
      restoreDeliveryAnswer(state, state.deliveryType, state.deliveryAnswer);
    },

    changeAnswer: (state, action) => {
      const { categoryIndex, questionIndex } = action.payload;
      const category = state.categoriesAnswers[categoryIndex];
      const questionAnswer = category.questionsAnswers[questionIndex];
      const { type } = questionAnswer.question;
      if (type === ONE_ANSWER) {
        const { answers } = questionAnswer.question;
        const last = answers.ua.length - 1;
        let { answer } = action.payload;
        if (typeof answers.ua[last] === "object" && answer === last)
          answer = "";
        questionAnswer.answer = answer;
      } else if (type === MULTIPLE_ANSWER) {
        const { selected } = action.payload;
        questionAnswer.answer = selected;
        const { answers } = questionAnswer.question;
        const last = answers.ua.length - 1;
        if (typeof answers.ua[last] === "object" && selected[last] === true) {
          questionAnswer.answer[last] = "";
        }
      } else if (type === TEXT_ANSWER ||
                 type === RANGE_ANSWER ||
                 type === CHILDREN_AGE_ANSWER ||
                 type === DELIVERY_ANSWER) {
        const { answer } = action.payload;
        questionAnswer.answer = answer;
        if (type === DELIVERY_ANSWER)
          state.deliveryAnswer = answer;
      }
      questionAnswer.validationError = validationError(questionAnswer);
      const invalidAnswers = category.questionsAnswers
                                     .filter(qa => validationError(qa) !== null);
      category.invalidAnswersCount = invalidAnswers.length;
      console.log('now invalidCount is');
      console.log(category.invalidAnswersCount);
    },
  },
});

export default quizSlice.reducer;

export const {
  setPrices,
  clearCategories,
  chooseCurrency,
  choosePrice,
  chooseDelivery,
  chooseBoxType,
  chooseCategories,
  setNoCategoriesError,
  chooseSurpriseAdult,
  chooseSurpriseChildren,
  chooseTestCategory,
  chooseMonthBox,
  changeAnswer,
} = quizSlice.actions;

export const selectPriceInfo = (state) => state.quiz.priceInfo;

export const selectCurrencyInfo = (state) => {
  const { currency } = state.quiz.priceInfo;
  const currencyIndex = currencies.indexOf(currency);
  return {
    currency,
    currencies,
    currencyIndex,
  };
}

export const selectDeliveryInfo = (state) => {
  const { deliveryType } = state.quiz;
  const deliveryIndex = deliveryTypes.indexOf(deliveryType);
  const { currency } = state.quiz.priceInfo;
  const deliveriesPrices = deliveryTypes.map(type => ({
    text: type,
    price: state.quiz.pricesCategories[currency].delivery[type]
  }));
  const deliveryPrice = deliveriesPrices[deliveryIndex].price;
  return {
    currency,
    deliveryType,
    deliveryPrice,
    deliveryIndex,
    deliveriesPrices,
  };
};

export const selectBoxInfo = (state) => {
  const { boxType } = state.quiz;
  const boxIndex = boxTypes.indexOf(boxType);
  const { currency } = state.quiz.priceInfo;
  const boxesPrices = boxTypes.map(type => ({
    text: type,
    price: state.quiz.pricesCategories[currency].box[type]
  }));
  const boxPrice = boxesPrices[boxIndex].price;
  return {
    currency,
    boxType,
    boxPrice,
    boxIndex,
    boxesPrices
  };
};

export const selectValidCategories = (state) => state.quiz.categoriesAnswers.map(c => c.invalidAnswersCount === 0);

export const selectAnswers = (state) => state.quiz.categoriesAnswers;

export const selectAllCategories = (state) =>
  state.quiz.allCategories.map((category) => category.name);

export const selectSelectedCategories = (state) => state.quiz.selected;

export const selectQuestionsAnswers = (state) => {
  const result = state.quiz.categoriesAnswers.map((category) => {
    const allCategories = [
      ...state.quiz.allCategories,
      testCategory,
      childrenQuestions,
      requiredQuestions,
      requiredQuestionsChildren,
      requiredQuestionsMonthBox
    ];
    const foundCategory = allCategories.find((c) => c.id === category.id);
    return {
      categoryId: category.id,
      answers: category.questionsAnswers.map((questionAnswer, qi) => {
        const { question } = questionAnswer;
        if (question.type === ONE_ANSWER) {
          if (typeof questionAnswer.answer === "string") {
            const lastIndex = foundCategory.questions[qi].answers.ua.length - 1;
            return {
              answerData: [lastIndex + "", questionAnswer.answer],
            };
          }
          const index = questionAnswer.answer;
          return {
            answerData: [index + ""],
          };
        }
        if (question.type === MULTIPLE_ANSWER) {
          let ans = question.answers.ua;
          let answers = [];
          let custom = [];
          for (let i = 0; i < ans.length; i++) {
            if (typeof questionAnswer.answer[i] === "string") {
              custom.push(questionAnswer.answer[i]);
              answers.push("true");
            } else answers.push(questionAnswer.answer[i] + "");
          }
          return {
            answerData: [...answers, ...custom],
          };
        }
        // TEXT_ANSWER | RANGE_ANSWER | DELIVERY_ANSWER
        return {
          answerData: [questionAnswer.answer + ""],
        };
      }),
    };
  });
  console.log(result);
  return result;
};

export const selectQuestionnaire = (state) => {
  const { priceInfo } = state.quiz;
  const { deliveryType, boxType } = state.quiz;
  return {
    language: localStorage.getItem('lang'),
    type: state.quiz.type,
    currency: priceInfo.currency,
    deliveryType,
    boxType,
    giftPrice: priceInfo.giftPrice,
    answers: selectQuestionsAnswers(state),
  };
};

export const selectUserAnswers = (lang) => (state) => {
  return state.quiz.categoriesAnswers.map((category) => {
    return {
      categoryName: category.name[lang],
      questionsAnswers: category.questionsAnswers.map((questionAnswer) => {
        const { question } = questionAnswer;
        let resultAnswer = "";
        if (question.type === ONE_ANSWER) {
          if (typeof questionAnswer.answer === "string") {
            resultAnswer = questionAnswer.answer;
          }
          else {
            const index = questionAnswer.answer;
            resultAnswer = question.answers[lang][index];
          }
        }
        else if (question.type === MULTIPLE_ANSWER) {
          let result = [];
          const answers = question.answers[lang];
          for (let i = 0; i < questionAnswer.answer.length; i++) {
            if (typeof questionAnswer.answer[i] === "string")
              result.push(questionAnswer.answer[i]);
            else if (questionAnswer.answer[i] === true)
              result.push(answers[i]);
          }
          resultAnswer = result.join('; ');
        }
        else if (question.type === CHILDREN_AGE_ANSWER)
        {
          const yearsTexts = {
            ua: ["рік", "роки", "років"],
            ru: ["год", "года", "лет"],
            en: ["year", "years", "years"],
          };
          const monthsTexts = {
            ua: ["місяць", "місяці", "місяців"],
            ru: ["месяц", "месяца", "мясяцев"],
            en: ["month", "months", "months"],
          };
          resultAnswer = numToTextAge(Number(questionAnswer.answer), yearsTexts[lang], monthsTexts[lang]);
        }
        else if (question.type === DELIVERY_ANSWER) {
          const selfDeliveryQAs = {
            ua: {
              question: 'Питання про доставку',
              answer: 'Ви обрали самовивіз'
            },
            ru: {
              question: 'Вопрос про доставку',
              answer: 'Вы выбрали самовывоз'
            },
            en: {
              question: 'Delivery question',
              answer: 'You choose self-delivery'
            },
          };
          const { delivery } = question;
          if (delivery === 'deliverySelf')
            return selfDeliveryQAs[lang];
          return {
            question: question[delivery].text[lang],
            answer: questionAnswer.answer
          }
        }
        // TEXT_ANSWER | RANGE_ANSWER | DELIVERY_ANSWER
        else resultAnswer = questionAnswer.answer;
        return {
          question: question.text[lang],
          answer: resultAnswer + ""
        };
      })
    };
  });
};
