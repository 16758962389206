import Page from "./Page";
import Link from '../components/Link';
import { useTranslation } from 'react-i18next';
import styles from './OrderFor.module.css';
import box1 from '../assets/malav-box.jpg';
import box2 from '../assets/vertical-box.jpg';
import Title from '../components/Title';

const OrderFor = (props) => {
  const { t } = useTranslation();
  return (
    <Page>
      <div className={styles.container}>
        {/* <img src={box1} />
        <img src={box2} /> */}
        {/* <div className={styles.content}> */}
          <Title text={t('box-catalog')} />
          <div className={styles.columns}>
            <div className={styles.monthBoxCol}>
              <h3 className={styles.first}>{t('box-month')}</h3>
              <p className={styles.first}>
                {t('box-month-det')}
              </p>
              <Link className={styles.first} to="/order/month">
                {t('order')}
              </Link>
            </div>
            <div className={styles.surpriseBoxCol}>
              <h3 className={styles.second}>{t('box-surprise')}</h3>
              <p className={styles.second}>
                {t('box-surprise-det')}
              </p>
              <Link className={styles.second} to="/order/surprise">
                {t('order')}
              </Link>
            </div>
          </div>
        {/* </div> */}
      </div>
    </Page>
  );
};

export default OrderFor;
