import Input from './UpTitleInput';
import { onPhoneKeyDown } from '../utils.js';

import styles from "./TextAnswerQuestion.module.css";

const TextAnswerQuestion = (props) => {
  const { text, onlyOne, placeholder, answerText, onChange } = props;
  const { type } = props;
  const onKeyDown = type === 'phone' ? onPhoneKeyDown : null;
  const onAnswerChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <>
      {
        onlyOne ?
          <h1>{text}</h1> :
          <p className={'question-text'}>{text}</p>
      }
      {
        placeholder ?
          <Input value={answerText}
                 placeholder={placeholder}
                 onChange={onAnswerChange}
                 onKeyDown={onKeyDown} />
          :
          <input type="text" className={styles.inp}
                 value={answerText} onKeyDown={onKeyDown} onChange={onAnswerChange} />
      }
    </>
  );
};

export default TextAnswerQuestion;
