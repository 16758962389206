import { useTranslation } from "react-i18next";

import { numToTextAge } from '../utils.js';
import RangeAnswerQuestion from './RangeAnswerQuestion';

const ChildrenAgeQuestion = (props) => {
  const { t } = useTranslation();
  const yearsTexts = [t('1-year'), t('2-4-years'), t('5-more-years')];
  const monthsTexts = [t('1-month'), t('2-4-months'), t('5-more-months')];

  // const { min, max, step } = question;
  const questionProps = {
    min: 1,   // 1 month
    max: 27,  // 27 - 12 + 1 = 16 years
    step: 1,
    text: props.text,
    value: props.value,
    onChange: props.onChange,
    onShowCaption: num => numToTextAge(num, yearsTexts, monthsTexts)
  };
  return (
    <RangeAnswerQuestion {...questionProps} />
  );
};

export default ChildrenAgeQuestion;
