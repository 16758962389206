import styles from './Button.module.css';

const Button = (props) => {
  const className = `${styles.btn} ${props.className}`
  return (
    <button style={props.style} className={className}
            onClick={props.onClick}>
      {props.children}
    </button>
  );
};

export default Button;
