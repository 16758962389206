import { useState } from 'react';

import Page from '../pages/Page';
import Button from '../components/Button';
import UpTitleInput from '../components/UpTitleInput';
import styles from './AdminLoginPage.module.css'
import { sendJsonPost } from '../api';

const AdminLoginPage = (props) => {
  const { tokenCallback } = props;
  const [email, setEmail] = useState('');
  const onEmailChange = (event) => setEmail(event.target.value);
  const [password, setPassword] = useState('');
  const onPasswordChange = (event) => setPassword(event.target.value);

  const onLogin = async () => {
    const resp = await sendJsonPost('/api/users/login', {
      email, password
    });
    if (resp.ok) {
      const body = await resp.json();
      const token = body.value;
      tokenCallback(token);
    }
    else
      alert('Ви ввели неправильний пароль / email');
  }

  return (
    <Page>
      <div className={styles.loginBox}>
        <UpTitleInput value={email}
                placeholder={"Email"}
                   onChange={onEmailChange} />
        <UpTitleInput value={password}
                placeholder={"Password"}
                   onChange={onPasswordChange} />
        <Button onClick={onLogin}>Login</Button>
      </div>
    </Page>
  );
};

export default AdminLoginPage;
