import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import OneAnswerQuestion from "../components/OneAnswerQuestion";
import { chooseCurrency, selectCurrencyInfo } from "../store/quizSlice";

const ChooseCurrency = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currencyInfo = useSelector(selectCurrencyInfo);
  const { currencyIndex } = currencyInfo;
  let currencies = currencyInfo.currencies.map(c => c.toUpperCase());
  const onCurrencyChange = (index) => {
    dispatch(chooseCurrency({
      currencyIndex: index
    }));
  };

  return (
    <OneAnswerQuestion text={t('choose-currency')}
                       options={currencies}
                       selected={currencyIndex}
                       onChange={onCurrencyChange}
                       inputName='currency' />
  );
};

export default ChooseCurrency;
