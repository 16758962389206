import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { chooseMonthBox } from '../store/quizSlice';
import { selectMonthBoxMenDescription,
         selectMonthBoxWomenDescription } from '../store/resourcesSlice';
import Quiz from '../components/Quiz';
import Button from '../components/Button';
import Title from '../components/Title';
import Page from './Page';

import ChooseCurrency from '../components/ChooseCurrency';
import ChooseDelivery from '../components/ChooseDelivery';
import ChooseBoxType from '../components/ChooseBoxType';
import ShowPrice from '../components/ShowPrice';

// import monthBoxMenImage from '../assets/men-newyear-box.jpg';
// import monthBoxWomenImage from '../assets/women-newyear-box.jpg';
import styles from './OrderMonthBoxPage.module.css';

const OrderDescription = (props) => {
  const { text, image } = props;
  return (
    <div className={styles.content}>
      <img src={image} alt='gift' />
      <div className={styles.text}>
        {text}
      </div>
    </div>
  );
};

const ChoosePricePage = (props) => {
  return (
    <div className={styles.selectBox}>
      <ChooseCurrency />
      <ChooseDelivery />
      <ChooseBoxType />
      <ShowPrice />
    </div>
  );
};

const OrderMonthBoxPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { surpriseFor } = props;
  useEffect(() => {
    dispatch(chooseMonthBox({ surpriseFor }));
  }, [dispatch]);
  const { i18n } = useTranslation();
  const { language } = i18n;
  const isForMen = surpriseFor === 'men';
  const selector = isForMen ?
    selectMonthBoxMenDescription : selectMonthBoxWomenDescription;
  const description = useSelector(selector);
  const text = description.text[language];
  const title = description.title[language];
  // const image = isForMen ? monthBoxMenImage : monthBoxWomenImage;
  const image = `/images/monthbox-${surpriseFor}.jpg`;
  const [pageIndex, setPageIndex] = useState(0);
  const pages = [
    () => <OrderDescription text={text} image={image} />,
    () => <ChoosePricePage />,
    () => <Quiz goPrev={() => setPageIndex(1)} />
  ];
  const incrementPageIndex = () => {
    setPageIndex(pageIndex + 1);
    window.scroll(0, 0);
  };
  const btn = <Button onClick={incrementPageIndex}
                      className={styles.next}>{t('next-btn')}</Button>;
  const button = pageIndex !== pages.length - 1 ? btn : null;

  return (
    <Page>
      <Title text={title} />
      {pages[pageIndex]()}
      {button}
    </Page>
  );
};

export default OrderMonthBoxPage;
