import Page from "./Page";
import Title from '../components/Title';
import styles from "./About.module.css";
import { useTranslation } from 'react-i18next';
import photo1 from '../assets/liuba2.jpg';
import photo2 from '../assets/olena2.jpg';

const About = (props) => {
  const { t } = useTranslation();
  return (
    <Page>
      <Title text={t('who-are-we')} />
      <div className={styles.container}>
        <h2>{t('giftbox-brand')}</h2>
        <p className={styles.mission}>
          {t('giftbox-mission-1')}
          {/* <span className={styles.malav}>{t('giftbox-mission-2')}</span>
          {t('giftbox-mission-3')} */}
        </p>
        <div className={styles.liuba}>
          <img src={photo1} />
          <div>
            <h3 className={styles.name}>{t('luba')} ❤️ </h3>
            {t('luba-about')}
          </div>
        </div>
        <div className={styles.olena}>
          <div>
            <h3 className={styles.name}>{t('lena')} ❤️ </h3>
            {t('lena-about')}
          </div>
          <img src={photo2} />
        </div>
      </div>
    </Page>
  );
};

export default About;

