import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import OneAnswerQuestion from "../components/OneAnswerQuestion";
import { chooseBoxType, selectBoxInfo } from "../store/quizSlice";

import styles from './ChooseBoxType.module.css';
import simpleBox from "../assets/simple-box.jpg";
import brandedBox from "../assets/branded-box.jpg";

const ChooseBoxType = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const boxInfo = useSelector(selectBoxInfo);
  const { boxIndex, boxesPrices } = boxInfo;
  const currency = boxInfo.currency.toUpperCase();
  const boxTypes = boxesPrices.map((bp) => `${t(bp.text)}: ${bp.price} ${currency}`);
  const onBoxChange = (index) => {
    dispatch(chooseBoxType({ boxIndex: index }));
  };

  const boxStyle = (index) => index === boxIndex ? styles.selectedBox : '';
  return (
    <div className={styles.chooseBoxTypeContainer}>
      <OneAnswerQuestion text={t('box-type-question')}
                         options={boxTypes}
                         selected={boxIndex}
                         onChange={onBoxChange}
                         inputName='boxtype' />
      <div className={styles.boxContainer}>
        <img className={boxStyle(0)} src={simpleBox} alt='simple box'></img>
        <img className={boxStyle(1)} src={brandedBox} alt='branded box'></img>
      </div>
    </div>
  );
};

export default ChooseBoxType;
