import { useState, useEffect, useRef } from "react";
import { NavLink } from 'react-router-dom';
import styles from './Navbar.module.css';
import { useTranslation } from 'react-i18next';

const Menu = ({ activeClass }) => {
  const { t } = useTranslation();
  const placeholder = "/";
  const className = activeClass + ' ' + styles.menu;
  const activeLinkStyle = ({isActive}) => isActive ? styles.activeLink : '';
  return (
    <ul className={className}>
      <li>
        <NavLink to="/">
          {t('menu-main')}
        </NavLink>
      </li>
      <li className={styles.aboutLink}>
        <NavLink to="/about" className={activeLinkStyle}>
          {t('menu-about')}
        </NavLink>
      </li>
      <li className={styles.detailedLink}>
        <NavLink to="/detailed" className={activeLinkStyle}>
          {t('detailed')}
        </NavLink>
      </li>
      <li>
        <NavLink to="/guarantees" className={activeLinkStyle}>
          {t('menu-guarantees')}
        </NavLink>
      </li>
      <li>
        <NavLink to="/contacts" className={activeLinkStyle}>
          {t('menu-contact')}
        </NavLink>
      </li>
    </ul>
  );
};

const useOutsideClick = (callback) => {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return ref;
};

const LangButton = () => {
  const { i18n } = useTranslation();
  const langs = ['ua', 'en', 'ru'];
  const [isActive, setActive] = useState(false);
  const dropdownClassName = isActive ? styles.dropdownContent : styles.hideDropdown
  const handleClickOutside = () => {
    setActive(false)
  };
  const ref = useOutsideClick(handleClickOutside);
  const toggleLang = () => {
    setActive(isActive => !isActive)
    // let index = langs.indexOf(i18n.language) + 1;
    // if (index === langs.length)
    //   index = 0;
    // localStorage.setItem('lang', langs[index]);
    // i18n.changeLanguage(langs[index]);
  };
  const changeLang = (e) => {
    localStorage.setItem('lang', e.target.textContent);
    i18n.changeLanguage(e.target.textContent);
  };
  const langIconClass = `fa fa-globe ${styles.langIcon}`;
  return (
    <li className={styles.lang}>
      <div ref={ref} className={styles.langButton} onClick={toggleLang}><i className={langIconClass}></i> {i18n.language}</div>
      <ul className={dropdownClassName}>
        <li onClick={changeLang}>{langs[0]}</li>
        <li onClick={changeLang}>{langs[1]}</li>
        <li onClick={changeLang}>{langs[2]}</li>
      </ul>
      {/* <i className={langIconClass} aria-hidden="true"></i> {i18n.language} */}
    </li>
  );
};

const BurgerButton = (props) => {
  const { onClick } = props;
  return (
    <li className={styles.burger}
          onClick={onClick}>
      <div className={styles.bar + ' ' + styles.bar1} />
      <div className={styles.bar + ' ' + styles.bar2} />
      <div className={styles.bar + ' ' + styles.bar3} />
    </li>
  );
};

const Navbar = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(prev => !prev);
  };
  const activeClass = showMenu ? styles.enable : '';
  // disable scrolling
  // if menu is opened
  useEffect(() => {
    document.body.style.overflow = showMenu ? 'hidden' : 'unset';
  }, [showMenu]);
  return (
      <ul className={styles.navmenu}>
        <li>
          <NavLink to="/" className={styles.logo}>
            MaLaV
          </NavLink>
        </li>
        <li><Menu activeClass={activeClass}/></li>
        <BurgerButton onClick={toggleMenu} />
        <LangButton />
      </ul>
  );
}

export default Navbar;
