import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styles from './OrderPreview.module.css'
import ShowAnswers from './ShowAnswers';
import { selectUserAnswers, selectPriceInfo } from '../store/quizSlice';
import { selectDeliveryInfo, selectBoxInfo } from '../store/quizSlice';

const OrderPreview = (props) => {
  const { i18n, t } = useTranslation();
  const { language } = i18n;
  const userAnswers = useSelector(selectUserAnswers(language));

  const priceInfo = useSelector(selectPriceInfo);
  const currency = priceInfo.currency.toUpperCase();

  const { deliveryType, deliveryPrice } = useSelector(selectDeliveryInfo);
  const deliveryInfo = `${t(deliveryType)}, ${deliveryPrice} ${currency}`;

  const { boxType, boxPrice } = useSelector(selectBoxInfo);
  const boxInfo = `${t(boxType)}, ${boxPrice} ${currency}`;

  const { giftPrice } = priceInfo;
  const allPrice = boxPrice + deliveryPrice + giftPrice;
  return (
    <div className={styles.container}>
      <p>{t('delivery-type')}: {deliveryInfo}</p>
      <p>{t('box-type')}: {boxInfo}</p>
      <p>{t('price-is')}: {giftPrice} {currency}</p>
      <p>{t('price-all')}: {allPrice} {currency}</p>
      <ShowAnswers answers={userAnswers} />
    </div>
  );
}

export default OrderPreview;
