import Navbar from '../components/Navbar';
import Footer from "../components/Footer";

const Page = (props) => {
  return <div className="main-container">
    <Navbar />
    <div className="content">
      {props.children}
    </div>
    <Footer />
  </div>;
};

export default Page;

