import Page from './Page';
import styles from './AdminLoadingPage.module.css';

const AdminLoadingPage = () => {
  return (
    <Page>
      <h1 className={styles.loading}>Завантаження...</h1>
    </Page>
  );
}

export default AdminLoadingPage;
