import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { chooseTestCategory } from '../store/quizSlice';

import Quiz from '../components/Quiz';
import Title from '../components/Title';
import Page from "./Page";

const OrderTestPage = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(chooseTestCategory());
  }, [dispatch]);

  return (
    <Page>
      <Title text='Quiz test page' />
      <Quiz />
    </Page>
  );
};

export default OrderTestPage;
