import './Checkbox.css';

const Checkbox = (props) => {
  const { onChange, checked, text, children } = props;

  return (
    <p>
      <label>
        <input type="checkbox"
               checked={checked}
               onChange={onChange} />
        <span>{text || children}</span>
      </label>
    </p>
  );
};

export default Checkbox;
